import update from 'react-addons-update';
import getDataLoadReducer from '../../src/reducers/dataLoadReducer';

export const MEMBER_DETAILS_LOADING = 'MEMBER_DETAILS_LOADING';
export const MEMBER_DETAILS_LOADED = 'MEMBER_DETAILS_LOADED';
export const MEMBER_DETAILS_FAILED = 'MEMBER_DETAILS_FAILED';
export const MEMBER_UPDATE_INITIATED = 'MEMBER_UPDATE_INITIATED';
export const MEMBER_UPDATE_FAILED = 'MEMBER_UPDATE_FAILED';
export const MEMBER_UPDATE_SUCCESS = 'MEMBER_UPDATE_SUCCESS';
export const MEMBER_UPDATE_PROPERTY = 'MEMBER_UPDATE_PROPERTY';
export const UPDATED_REQUIRED_INFORMATION_SUCCESS = 'UPDATED_REQUIRED_INFORMATION_SUCCESS';

export const getMember = state => state.member.resource;
export const getMemberFirstName = state => state.member.resource.firstName;
export const getMemberLastName = state => state.member.resource.lastName;
export const isLoading = state => state.member.isLoading;
export const hasError = state => state.member.hasError;
export const getPhoneNumber = state => state.member.resource.phoneNumber;
export const getEmailAddress = state => state.member.resource.emailAddress;
export const areMemberDetailsRequired = state => state.member.areMemberDetailsRequired;
export const isSaving = state => state.member.resource.isSaving;
export const getMemberUpdateLink = state => state.member.resource._links.memberUpdate.href;

const dataReducer = getDataLoadReducer(
  {
    resource: {
      _links: { memberUpdate: { href: '' } },
      isSaving: false,
    },
    isLoading: false,
    hasError: false,
    areMemberDetailsRequired: false,
  },
  MEMBER_DETAILS_LOADED,
  MEMBER_DETAILS_LOADING,
  MEMBER_DETAILS_FAILED,
);

const areMemberDetailsRequiredFor = (response) => {
  const hasBothEmailAndPhone = response.emailAddress && response.phoneNumber;
  const emailAndPhoneEmpty = !response.emailAddress && !response.phoneNumber;
  return !(hasBothEmailAndPhone || emailAndPhoneEmpty);
};

const reducer = (state, action) => {
  switch (action.type) {
    case MEMBER_UPDATE_INITIATED:
      return update(state, { resource: { isSaving: { $set: true } } });
    case MEMBER_UPDATE_SUCCESS:
      return update(state, {
        areMemberDetailsRequired: { $set: false },
        resource: { $set: action.resource },
      });
    case MEMBER_UPDATE_FAILED:
      return update(state, {
        resource: {
          isSaving: { $set: false },
          errors: { $set: action.resource.errors },
        },
      });
    case MEMBER_DETAILS_LOADED:
      return dataReducer(update(state,
        {
          areMemberDetailsRequired: {
            $set: areMemberDetailsRequiredFor(action.response),
          },
        }), action);
    case MEMBER_UPDATE_PROPERTY:
      return update(state, {
        resource: {
          [action.property]: { $set: action.value },
          hasChanges: { $set: true },
        },
      });
    default:
      return dataReducer(state, action);
  }
};

export default reducer;
