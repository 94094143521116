import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouteLink } from 'react-router-dom';

export const Link = ({ route, children, external, onClick }) => (
  (route.startsWith('http') || external)
    ? <a onClick={onClick} href={route} target="_blank" rel="noopener noreferrer">{children}</a>
    : <RouteLink onClick={onClick} to={route}>{children}</RouteLink>
);

Link.defaultProps = {
  route: '',
  external: false,
};

Link.propTypes = {
  route: PropTypes.string,
  external: PropTypes.bool,
  children: PropTypes.string.isRequired,
};
