import getDataLoadReducer from '../../src/reducers/dataLoadReducer';

export const PAYMENT_UPDATE_LOADING = 'PAYMENT_UPDATE_LOADING';
export const PAYMENT_UPDATE_FAILED = 'PAYMENT_UPDATE_FAILED';
export const PAYMENT_UPDATE_LOADED = 'PAYMENT_UPDATE_LOADED';
export const PAYMENT_UPDATE_CLICKED = 'PAYMENT_UPDATE_CLICKED';

export const isLoading = state => state.paymentUpdate.isLoading;
export const hasError = state => state.paymentUpdate.hasError;
export const getPaymentUpdateUrlFrom =
  state => state.paymentUpdate.resource._links.paymentUpdate.href;
export const getBodyFrom = state => state.paymentUpdate.resource.body;

const dataReducer = getDataLoadReducer(
  {
    resource: {
      body: {},
      _links: { paymentUpdate: { href: '', method: '' } },
    },
    hasError: false,
    isLoading: false,
  },
  PAYMENT_UPDATE_LOADED,
  PAYMENT_UPDATE_LOADING,
  PAYMENT_UPDATE_FAILED);

const reducer = (state, action) => dataReducer(state, action);

export default reducer;
