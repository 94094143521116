import PropTypes from 'prop-types';
import React from 'react';
import { TextField } from './TextField';

export default class EmailTextField extends React.Component {
  constructor(props) {
    super(props);
    this.onInput = this.onInput.bind(this);
    this.state = { value: this.props.value };
  }

  /* eslint-disable no-useless-escape */
  onInput(event) {
    this.setState({ value: event.target.value });
    if (event.target.value.match(/^[A-Za-z0-9.\-_!$@#%"&'*+\/=?^`{}|~]+[@][A-Za-z0-9.-]+[.]{1}[A-Za-z0-9]/)) {
      this.props.onInput(event);
    }
  }

  render() {
    return (<span>
      <TextField
        className={this.props.className}
        value={this.state.value}
        onInput={this.onInput}
      />
    </span>);
  }
}

EmailTextField.propTypes = {
  onInput: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};
