import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Panel, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { agreeConsent, loadConsent } from '../../actions/login/ConsentAction';
import { hasConsentErrors, isAgreeConsentLoading, isConsented, isConsentLoading } from '../../reducers/loginReducers';
import { ACCOUNT_OVERVIEW } from '../../utils/Routes';
import { FetchPanel } from '../commons/FetchPanel';
import ConnectedRouteDirector from '../commons/RouteDirector';
import Submit from '../commons/Submit';
import { ElectronicConsent } from './ElectronicConsent';

export class ConsentToDoingBusiness extends Component {
  componentDidMount() {
    this.props.loadConsent();
  }

  definition = { true: ACCOUNT_OVERVIEW };

  render() {
    return (
      <span>
        <Row>
          <Col xs={12}>
            <FetchPanel
              isLoading={this.props.isLoading}
              title="Logging in..."
              hasError={this.props.hasError}
            >
              <Panel
                header="Consent to do business electronically"
              >
                <ElectronicConsent />
                <br />
                <strong>Consent</strong>
                <br />
                <br />
                <p>I agree to do business electronically and to the use of electronic records
                  in&nbsp;
                  connection with New Dental Choice. I have been able to read this notice
                  using&nbsp;
                  my computer and software. I have access to an account with an internet&nbsp;
                  service provider and I am able to send and receive email.</p>
                <p>
                  <Submit
                    isLoading={this.props.isSaving}
                    onClick={this.props.agreeConsent}
                  >
                    I consent
                  </Submit>
                </p>
              </Panel>
            </FetchPanel>
          </Col>
        </Row>
        <ConnectedRouteDirector
          history={this.props.history}
          on={this.props.isConsented}
          definition={this.definition}
        />
      </span>);
  }
}

ConsentToDoingBusiness.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  isConsented: PropTypes.bool.isRequired,
  loadConsent: PropTypes.func.isRequired,
  agreeConsent: PropTypes.func.isRequired,
  history: PropTypes.shape.isRequired,
};

export const mapStateToProps = state => ({
  isLoading: isConsentLoading(state),
  hasErrors: hasConsentErrors(state),
  isSaving: isAgreeConsentLoading(state),
  isConsented: isConsented(state),
});

export const mapDispatchToProps = dispatch => ({
  loadConsent: () => dispatch(loadConsent()),
  agreeConsent: () => dispatch(agreeConsent()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConsentToDoingBusiness));
