import * as React from 'react';
import DateFormatter from '../commons/DateFormatter';

export const PaymentHistoryRow = (props) => {
  const {
    date,
    source,
    status,
    amount,
  } = props.transaction || {};

  return (
    <tr>
      <td><DateFormatter date={date} /></td>
      <td>{source}</td>
      <td>{status}</td>
      <td>${amount}</td>
    </tr>);
};
