import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ConnectedRequireInformationModal from '../commons/RequireInformationModal';
import ConnectedMemberCardPanel from '../memberCard/MemberCardPanel';
import ConnectedPaymentSummary from '../payments/PaymentSummary';
import ConnectedMemberWelcome from '../planSummary/MemberWelcome';
import ConnectedPlanSummary from '../planSummary/PlanSummary';

export const MyAccount = () => (
  <span>
    <ConnectedRequireInformationModal />
    <Row>
      <Col xs={6}>
        <ConnectedMemberWelcome />
      </Col>
    </Row>
    <Row>
      <Col xs={12} md={6}>
        <ConnectedPlanSummary />
      </Col>
      <Col xs={12} md={6}>
        <ConnectedPaymentSummary />
        <ConnectedMemberCardPanel />
      </Col>
    </Row>
  </span>
);
