import PropTypes from 'prop-types';
import React from 'react';
import MaskedInput from 'react-text-mask';

export default class PhoneTextField extends React.Component {
  constructor(props) {
    super(props);
    this.onInput = this.onInput.bind(this);
  }

  onInput(event) {
    const numericOnlyEvent = { target: { value: event.target.value.replace(/\D/g, '') } };
    if (numericOnlyEvent.target.value.length === 10) {
      this.props.onChange(numericOnlyEvent);
    }
  }

  render() {
    return (<span>
      <MaskedInput
        mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        placeholder="(555) 555-5555"
        className="form-control"
        value={this.props.value}
        onChange={this.onInput}
        onPaste={this.onInput}
      />
    </span>);
  }
}

PhoneTextField.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};
