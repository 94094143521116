import PropTypes from 'prop-types';
import React from 'react';

const Submit = (props) => {
  const disabled = (props.disabled) ? props.disabled : false;
  const isLoading = (props.isLoading) ? props.isLoading : false;
  const className = (props.className) ? props.className : 'btn btn-primary';

  return (
    <button
      className={className}
      type="submit"
      onClick={props.onClick}
      disabled={disabled || isLoading}
    >
      {isLoading && <i className="fa fa-spinner fa-spin" style={{ fontSize: '1em', marginRight: '10px' }} />}
      {props.children}
    </button>);
};

Submit.propTypes = {
  disabled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
};

export default Submit;
