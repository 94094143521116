import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ConnectedPaymentSummary from '../../payments/PaymentSummary';
import ConnectedPlanSummary from '../../planSummary/PlanSummary';

export const PendingContractDashboard = () => (
  <Row>
    <Col xs={12} md={6}>
      <ConnectedPlanSummary />
    </Col>
    <Col xs={12} md={6}>
      <ConnectedPaymentSummary />
    </Col>
  </Row>);
