import {
  AGREE_CONSENT_FAILURE,
  AGREE_CONSENT_INITIATED,
  AGREE_CONSENT_SUCCESS,
  LOAD_CONSENT_FAILURE,
  LOAD_CONSENT_INITIATED,
  LOAD_CONSENT_SUCCESS,
} from '../../reducers/loginReducers';
import { post } from '../../utils/api';
import { AGREE_CONSENT, LOAD_CONSENT } from '../../utils/Links';

const patchAgreeConsent = url => dispatch =>
  post(url)
    .then(() => dispatch({ type: AGREE_CONSENT_SUCCESS }))
    .catch(() => dispatch({ type: AGREE_CONSENT_FAILURE }));

export const agreeConsent = () => ({
  urlName: AGREE_CONSENT,
  fetch: patchAgreeConsent,
  initiate: AGREE_CONSENT_INITIATED,
  failure: AGREE_CONSENT_FAILURE,
});

export const loadConsent = () => ({
  urlName: LOAD_CONSENT,
  initiate: LOAD_CONSENT_INITIATED,
  loaded: LOAD_CONSENT_SUCCESS,
  failure: LOAD_CONSENT_FAILURE,
});
