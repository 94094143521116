import PropTypes from 'prop-types';
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { fetchUserDetails } from '../../actions/login/LocateUserAction';
import { getIsLocateUserLoading, hasApplicationError, hasError, isAccountLocked, minutesLockedFor } from '../../reducers/loginReducers';
import { AccountLockedErrorMessage } from '../commons/AccountLockedErrorMessage';
import { AlphaNumericTextField } from '../commons/AlphaNumericTextField';
import { DateField } from '../commons/DateField';
import Form from '../commons/Form';
import Submit from '../commons/Submit';
import { SupportPhoneNumber } from '../commons/SupportPhoneNumber';
import { LocateMemberMarketingPanel } from './LocateMemberMarketingPanel';
import { ApplicationErrorMessage } from '../commons/ApplicationErrorMessage';

export class LocateMember extends React.Component {
  constructor(props) {
    super(props);
    this.state = { memberId: '', memberDob: '' };
  }

  onClick = () => this.props.fetchUserDetails(this.state.memberId, this.state.memberDob);

  showAlertMessage = () => (
    <div>
      {this.props.hasError &&
      <div className="alert alert-warning alert-icon alert-dismissible" role="alert">
        Sorry, we're not finding a match for this information.
        You can try again or call customer support at <SupportPhoneNumber />.
      </div>}
    </div>
  );

  updateMemberId = event => this.setState({ memberId: event.target.value.toUpperCase() });

  updateMemberDob = date => this.setState({ memberDob: date });

  _validationErrorsExist = () => !this.state.memberId || !this.state.memberDob;

  render() {
    return (
      <Form onSubmit={this.onClick}>
        {this.showAlertMessage()}
        {this.props.isAccountLocked && <AccountLockedErrorMessage minutesLockedFor={this.props.minutesLockedFor} />}
        {this.props.hasApplicationError && <ApplicationErrorMessage />}
        <Row>
          <Col xs={12} lg={6}>
            <div className="row">
              <div className="col-md-8">
                <h1>Log in</h1>
                <p>Please provide the following information so we can locate your account.</p>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-8">
                <small className="is-required-header pull-right">Required</small>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-8">
                <div className="form-group">
                  <label className="is-required" htmlFor="memberId">Membership ID</label>
                  <AlphaNumericTextField
                    value={this.state.memberId}
                    className="memberId"
                    name="memberId"
                    maxLength="12"
                    onInput={this.updateMemberId}
                    onPaste={this.updateMemberId}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-8">
                <div className="form-group">
                  <label className="is-required" htmlFor="memberDob">Member date of birth</label>
                  <DateField
                    name="memberDob"
                    date={this.state.memberDob}
                    update={this.updateMemberDob}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <br />
                <Submit
                  disabled={this._validationErrorsExist()}
                  isLoading={this.props.isLoading}
                >Continue</Submit>
              </div>
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <LocateMemberMarketingPanel />
          </Col>
        </Row>
      </Form>
    );
  }
}

export const mapDispatchToProps = dispatch => ({
  fetchUserDetails: (memberId, memberDob) => dispatch(fetchUserDetails({ memberId, memberDob })),
});

export const mapStateToProps = state => ({
  hasError: hasError(state),
  isLoading: getIsLocateUserLoading(state),
  isAccountLocked: isAccountLocked(state),
  minutesLockedFor: minutesLockedFor(state),
  hasApplicationError: hasApplicationError(state),
});

LocateMember.propTypes = {
  fetchUserDetails: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isAccountLocked: PropTypes.bool.isRequired,
  minutesLockedFor: PropTypes.string.isRequired,
  hasApplicationError: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocateMember);
