import getDataLoadReducer from '../../src/reducers/dataLoadReducer';

export const VALIDATE_SESSION_LOADING = 'VALIDATE_SESSION_LOADING';
export const VALIDATE_SESSION_LOADED = 'VALIDATE_SESSION_LOADED';
export const VALIDATE_SESSION_FAILED = 'VALIDATE_SESSION_FAILED';

export const within2Minutes = state => state.validateSession.resource.within2Minutes;

const reducer = getDataLoadReducer(
  {
    resource: {},
    isLoading: false,
    hasError: false,
  },
  VALIDATE_SESSION_LOADED,
  VALIDATE_SESSION_LOADING,
  VALIDATE_SESSION_FAILED);
export default reducer;
