import PropTypes from 'prop-types';
import React from 'react';
import ndcLogo from '../../images/NDC_Logo_blue.png';
import { LOGIN } from '../../utils/Routes';
import NavBarItem from './NavBarItem';

export default class NavBar extends React.Component {
  brandWithNdcLogo = () => (
    <a className="navbar-brand" href={'https://www.newdentalchoice.com'}>
      <img className="navbar-ndc-brand" src={ndcLogo} alt="New Dental Choice Logo" />
    </a>
  );

  render() {
    if (!this.props.navItems || this.props.navItems.length === 0 || this.props.pathname.includes(LOGIN)) {
      return (
        <span>
          <nav className="navbar navbar-default">
            <div className="container-fluid">
              <div className="navbar-header">
                {this.brandWithNdcLogo()}
              </div>
            </div>
          </nav>
        </span>
      );
    }
    return (
      <span>
        <nav className="navbar navbar-default">
          <div className="container-fluid">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#bs-example-navbar-collapse-1"
                aria-expanded="false"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar" />
                <span className="icon-bar" />
                <span className="icon-bar" />
              </button>
              {this.brandWithNdcLogo()}
            </div>
            <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
              {this.props.navItems.map(navItem => (<NavBarItem
                pathname={this.props.pathname}
                push={this.props.push}
                navItem={navItem}
                key={navItem.label}
              />))}
            </div>
          </div>
        </nav>
      </span>
    );
  }
}

NavBar.propTypes = {
  navItems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  pathname: PropTypes.string.isRequired,
  push: PropTypes.func.isRequired,
};
