import update from 'react-addons-update';
import getDataLoadReducer from '../../src/reducers/dataLoadReducer';

export const MEMBER_CARD_TOGGLE_SIDE = 'MEMBER_CARD_TOGGLE_SIDE';
export const MEMBER_CARD_DOWNLOAD = 'MEMBER_CARD_DOWNLOAD';

export const MEMBER_CARD_DETAILS_LOADING = 'MEMBER_CARD_DETAILS_LOADING';
export const MEMBER_CARD_DETAILS_LOADED = 'MEMBER_CARD_DETAILS_LOADED';
export const MEMBER_CARD_DETAILS_FAILED = 'MEMBER_CARD_DETAILS_FAILED';
export const CLEAR_MEMBER_CARD_DETAILS = 'CLEAR_MEMBER_CARD_DETAILS';

const dataReducer = getDataLoadReducer(
  {
    resource: {},
    isLoading: false,
    hasError: false,
    showFront: true,
  },
  MEMBER_CARD_DETAILS_LOADED,
  MEMBER_CARD_DETAILS_LOADING,
  MEMBER_CARD_DETAILS_FAILED,
);

const reducer = (state, action) => {
  switch (action.type) {
    case MEMBER_CARD_TOGGLE_SIDE: {
      return update(state, { showFront: { $set: !state.showFront } });
    }
    case MEMBER_CARD_DOWNLOAD: {
      return state;
    }
    case CLEAR_MEMBER_CARD_DETAILS: {
      return update(state, { resource: { $set: {} } });
    }
    default:
      return dataReducer(state, action);
  }
};

export const showFront = state => state.memberCard.showFront;
export const isLoading = state => state.memberCard.isLoading;
export const base64EncodedCardData = state => state.memberCard.resource.base64EncodedCardData;
export const hasError = state => state.memberCard.hasError;

export default reducer;
