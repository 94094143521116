import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, ControlLabel, Form, FormGroup, Panel, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { submitPaymentUpdateSurvey } from '../../actions/paymentSummary/PaymentSurveyAction';
import { getLink } from '../../reducers/linksReducer';
import { PAYMENT_SURVEY } from '../../utils/Links';
import { ACCOUNT_OVERVIEW } from '../../utils/Routes';
import { Link } from '../commons/Link';
import { RadioButtons } from '../commons/RadioButtons';
import { getPlan } from '../../reducers/planSummaryReducers';

export class PaymentSurvey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      question1: '',
      question2: '',
      question3: '',
    };

    this.submitSurvey = this.submitSurvey.bind(this);
    this.saveSurveyQuestion1 = this.saveSurveyQuestion1.bind(this);
    this.saveSurveyQuestion2 = this.saveSurveyQuestion2.bind(this);
    this.saveSurveyQuestion3 = this.saveSurveyQuestion3.bind(this);
  }

  submitSurvey() {
    const survey = { ...this.state };
    survey.contractId = this.props.contractId;
    this.props.submitSurvey(this.props.paymentSurveyLink, survey);
    this.props.history.push(ACCOUNT_OVERVIEW);
  }

  saveSurveyQuestion1(response) {
    this.setState({ question1: response });
  }

  saveSurveyQuestion2(response) {
    this.setState({ question2: response });
  }

  saveSurveyQuestion3(event) {
    this.setState({ question3: event.target.value });
  }

  render() {
    return (
      <span>
        <Row>
          <Link route={ACCOUNT_OVERVIEW}>Continue to your account overview</Link>
        </Row>
        <br />
        <Row>
          <Panel header="Tell us how changing your payment method went">
            <Form onSubmit={this.submitSurvey}>
              <Col sm={12}>
                <Row>
                  <FormGroup>
                    <ControlLabel>Was the process clear and easy?</ControlLabel>
                    <br />
                    <RadioButtons click={this.saveSurveyQuestion1} />
                  </FormGroup>
                </Row>
                <Row>
                  <FormGroup>
                    <ControlLabel>Was the result what you were looking for?</ControlLabel>
                    <br />
                    <RadioButtons click={this.saveSurveyQuestion2} />
                  </FormGroup>
                </Row>
                <Row>
                  <FormGroup>
                    <ControlLabel>Any other comments?</ControlLabel>
                    <br />
                    <textarea className="col-xs-12" rows="3" maxLength="200" onInput={this.saveSurveyQuestion3}>
                      {this.state.question3}
                    </textarea>
                  </FormGroup>
                </Row>
                <br />
                <Row>
                  <Button type="submit" bsStyle="primary" block>Submit Comments</Button>
                </Row>
              </Col>
            </Form>
          </Panel>
        </Row>
      </span>
    );
  }
}

PaymentSurvey.propTypes = {
  submitSurvey: PropTypes.func.isRequired,
  paymentSurveyLink: PropTypes.string.isRequired,
  contractId: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export const mapDispatchToProps = dispatch => ({
  submitSurvey: (memberSurveyLink, surveyValues) => dispatch(submitPaymentUpdateSurvey(memberSurveyLink, surveyValues)),
});

export const mapStateToProps = state => ({
  paymentSurveyLink: getLink(state, PAYMENT_SURVEY),
  contractId: getPlan(state).contractId,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentSurvey));
