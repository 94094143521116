import update from 'react-addons-update';
import getDataLoadReducer from './dataLoadReducer';
import { DEPENDENTS_REMOVE_SUCCESS } from './dependentsReducer';

export const DEPENDENTS_NOT_ENROLLED_LOADING = 'DEPENDENTS_NOT_ENROLLED_LOADING';
export const DEPENDENTS_NOT_ENROLLED_LOADED = 'DEPENDENTS_NOT_ENROLLED_LOADED';
export const DEPENDENTS_NOT_ENROLLED_FAILED = 'DEPENDENTS_NOT_ENROLLED_FAILED';
export const DEPENDENTS_RE_ENROLL_INITIATED = 'DEPENDENTS_RE_ENROLL_INITIATED';
export const DEPENDENTS_RE_ENROLL_SUCCESS = 'DEPENDENTS_RE_ENROLL_SUCCESS';
export const DEPENDENTS_RE_ENROLL_FAILED = 'DEPENDENTS_RE_ENROLL_FAILED';

export const isLoading = state => state.notEnrolledDependents.isLoading;
export const dependents = state => state.notEnrolledDependents.resource.dependents;

const dataReducer = getDataLoadReducer(
  {
    resource: { dependents: [] },
    isLoading: false,
    hasError: false,
  },
  DEPENDENTS_NOT_ENROLLED_LOADED,
  DEPENDENTS_NOT_ENROLLED_LOADING,
  DEPENDENTS_NOT_ENROLLED_FAILED,
);

const getIndex = (state, action) => state.resource.dependents.findIndex(dependent => dependent.id === action.key);

const reducer = (state, action) => {
  switch (action.type) {
    case DEPENDENTS_REMOVE_SUCCESS:
      return update(state, {
        resource: {
          dependents: {
            $push: [action.resource],
          },
        },
      });
    case DEPENDENTS_RE_ENROLL_SUCCESS: {
      const index = getIndex(state, action);
      return update(state, {
        resource: {
          dependents: {
            $splice: [[index, 1]],
          },
        },
      });
    }
    case DEPENDENTS_RE_ENROLL_INITIATED: {
      const index = getIndex(state, action);
      return update(state, {
        resource: {
          dependents: {
            [index]: {
              isReEnrolling: { $set: true },
            },
          },
        },
      });
    }
    case DEPENDENTS_RE_ENROLL_FAILED: {
      const index = getIndex(state, action);
      return update(state, {
        resource: {
          dependents: {
            [index]: {
              reEnrollErrors: { $set: action.resource.errors },
              isReEnrolling: { $set: false },
            },
          },
        },
      });
    }
    default:
      return dataReducer(state, action);
  }
};

export default reducer;
