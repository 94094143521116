import { getLink, STORE_LINKS } from '../reducers/linksReducer';
import { get } from '../utils/api';

function getFetch(url, params, loaded, failure) {
  return dispatch => get(url, params)
    .then(({ data }) => dispatch({ type: loaded, response: data }))
    .catch(() => dispatch({ type: failure }));
}

function handleFetch(store, next, initiate, urlName, passedFetch, failure, loaded, params) {
  const fetch = passedFetch || getFetch;
  if (!failure) throw new Error('Must pass a \'failure\' action when fetching');
  if (initiate) next({ type: initiate });

  const link = getLink(store.getState(), urlName);
  if (link) {
    return store.dispatch(fetch(link, params, loaded, failure));
  }
  return get('/api/v1').then(({ data }) => {
    store.dispatch({ type: STORE_LINKS, response: data });
    return store.dispatch(fetch(data._links[urlName].href, params, loaded, failure));
  }).catch(() => {
    next({ type: failure });
  });
}

export default store =>
  next => ({ urlName, initiate, fetch, failure, loaded, params, ...rest }) => {
    if ((fetch || loaded) && urlName) {
      return handleFetch(store, next, initiate, urlName, fetch, failure, loaded, params);
    }
    return next(rest);
  };
