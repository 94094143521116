import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import { routeOn } from '../../utils/RouteOn';

export class RouteDirector extends React.Component {
  componentDidMount() {
    routeOn(this.props.history.push, this.props.on, this.props.definition, this.props.location.pathname);
  }

  componentDidUpdate() {
    routeOn(this.props.history.push, this.props.on, this.props.definition, this.props.location.pathname);
  }

  render() {
    return <span />;
  }
}

RouteDirector.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  on: PropTypes.string.isRequired,
  definition: PropTypes.shape().isRequired,
};

export default withRouter(RouteDirector);
