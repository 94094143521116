import PropTypes from 'prop-types';
import React from 'react';
import { FailedPanel } from './FailedPanel';
import { LoadingPanel } from './LoadingPanel';

export class FetchPanel extends React.Component {
  render() {
    if (this.props.hasError) {
      return <FailedPanel title={this.props.title} />;
    }
    if (this.props.isLoading) {
      return (
        <LoadingPanel title={this.props.title} />
      );
    }
    return (this.props.children);
  }
}

FetchPanel.propTypes = {
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
};
