import { VALIDATE_SESSION_FAILED, VALIDATE_SESSION_LOADED, VALIDATE_SESSION_LOADING } from '../../reducers/validateSessionReducer';
import { post } from '../../utils/api';
import { STAY_LOGGED_IN, VALIDATE_SESSION } from '../../utils/Links';

export const logout = () => post('api/v1/logout');

export const logoutAction = () => ({
  type: 'LOGOUT',
});

export const validateSession = () => ({
  urlName: VALIDATE_SESSION,
  loaded: VALIDATE_SESSION_LOADED,
  initiate: VALIDATE_SESSION_LOADING,
  failure: VALIDATE_SESSION_FAILED,
});

export const stayLoggedIn = () => ({
  urlName: STAY_LOGGED_IN,
  loaded: VALIDATE_SESSION_LOADED,
  initiate: VALIDATE_SESSION_LOADING,
  failure: VALIDATE_SESSION_FAILED,
});
