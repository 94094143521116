import PropTypes from 'prop-types';
import React from 'react';
import { Panel } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';
import { DANGER } from '../../properties/colors';

export const FailedPanel = props => (
  <Panel header={props.title} bsStyle="danger">
    <span className="text-center" style={{ color: DANGER }}>
      <p><FontAwesome name="times-circle-o" style={{ fontSize: '3em' }} /></p>
      <p>Failed to Load</p>
    </span>
  </Panel>
);

FailedPanel.propTypes = {
  title: PropTypes.string.isRequired,
};
