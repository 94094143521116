import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Route } from 'react-router';
import ConnectedHasConsentCheck from '../electronicConsent/HasConsentedCheck';
import ConnectedHasTerminatedContractCheck from '../terminatedContract/HasTerminatedContractCheck';
import ConnectedLogoutWarningModal from './LogoutWarningModal';

export const AuthenticatedRoutingRule = ({ component: Component, title, ...rest }) => (
  <span>
    <Helmet title={title} />
    <ConnectedHasConsentCheck />
    <ConnectedHasTerminatedContractCheck />
    <ConnectedLogoutWarningModal />
    <Route
      {...rest}
      render={matchProps => (
        <Component {...matchProps} />
      )}
    />
  </span>
);

AuthenticatedRoutingRule.propTypes = {
  component: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
};
