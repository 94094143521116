import getDataLoadReducer from '../../src/reducers/dataLoadReducer';

export const PLAN_DETAILS_LOADING = 'PLAN_DETAILS_LOADING';
export const PLAN_DETAILS_LOADED = 'PLAN_DETAILS_LOADED';
export const PLAN_DETAILS_FAILED = 'PLAN_DETAILS_FAILED';

const dataReducer = getDataLoadReducer(
  {
    resource: {},
    isLoading: false,
    hasError: false,
  },
  PLAN_DETAILS_LOADED,
  PLAN_DETAILS_LOADING,
  PLAN_DETAILS_FAILED,
);

export const isLoading = state => state.plan.isLoading;
export const hasError = state => state.plan.hasError;
export const getPlan = state => state.plan.resource;
export const getPlanType = state => state.plan.resource.type;
export const getPlanContractId = state => state.plan.resource.contractId;

export default dataReducer;
