import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { sendToken } from '../../actions/login/SendTokenAction';
import { email, emailLink, getIsSendSecurityCodeLoading, hasError, phone, smsLink } from '../../reducers/loginReducers';
import Form from '../commons/Form';
import Submit from '../commons/Submit';
import ExplainPasswordlessLoginModal from '../login/ExplainPasswordlessLoginModal';
import ExplainPasswordlessModalLink from './ExplainPasswordlessModalLink';

export class RequestTokenDestination extends React.Component {
  showAlertMessage = () => {
    if (this.props.showErrorMessage) {
      return (
        <div className="alert alert-warning alert-icon alert-dismissible" role="alert">
          We&apos;re sorry, it looks like your unique security code didn&apos;t reach you.
          You can try again or call customer support at 888-632-7558.
        </div>
      );
    }
    return (<div />);
  };

  showCorrectSubHeadingText = () => {
    if (this.props.showErrorMessage) {
      return (
        <p className="onErrorText">
          Want to try again? How would you like to receive your code?
          &nbsp; {<ExplainPasswordlessModalLink />}
        </p>
      );
    }

    return (
      <p className="initialText">
        To verify your identity and
        &nbsp;protect your account, we&apos;ll send you a unique security code
        &nbsp;so you can access your information. How would you like to receive your
        code? &nbsp; {<ExplainPasswordlessModalLink />}
      </p>
    );
  };
  sendTokenViaEmail = () => this.props.sendToken(this.props.emailLink);
  sendTokenViaSms = () => this.props.sendToken(this.props.smsLink);

  render() {
    const boxSize = this.props.email && this.props.phone ? 'col-xs-12 col-md-6' : 'col-xs-12 col-md-12';
    return (
      <Row>
        <Col xs={12} lg={12}>
          <div className="request-token-destination">
            {this.showAlertMessage()}
            <div>
              <h1>Great to see you!</h1>
              {this.showCorrectSubHeadingText()}
            </div>
            <br />
            <br />
            <div>
              {this.props.email &&
                <div className={boxSize}>
                  <div className="form-group text-center col-xs-12 emailCode">
                    <Form onSubmit={this.sendTokenViaEmail}>
                      <i className="fa fa-envelope fa-5x" />
                      <p className="email">{this.props.email}</p>
                      <Submit isLoading={this.props.isLoading}>Email me the
                        code</Submit>
                    </Form>
                  </div>
                </div>}
              {this.props.phone &&
              <div className={boxSize}>
                <div className="form-group text-center col-xs-12 smsCode">
                  <Form onSubmit={this.sendTokenViaSms}>
                    <i className="fa fa-mobile fa-5x" />
                    <p className="phone">{this.props.phone}</p>
                    <Submit isLoading={this.props.isLoading}>Text me the
                      code</Submit>
                  </Form>
                </div>
              </div>}
            </div>
            <ExplainPasswordlessLoginModal />
          </div>
        </Col>
      </Row>
    );
  }
}

export const mapDispatchToProps = dispatch => ({
  sendToken: url => dispatch(sendToken(url)),
});

export const mapStateToProps = state => ({
  hasError: hasError(state),
  email: email(state),
  phone: phone(state),
  isLoading: getIsSendSecurityCodeLoading(state),
  emailLink: emailLink(state),
  smsLink: smsLink(state),
});

RequestTokenDestination.propTypes = {
  showErrorMessage: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  phone: PropTypes.string.isRequired,
  emailLink: PropTypes.string.isRequired,
  smsLink: PropTypes.string.isRequired,
  sendToken: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestTokenDestination);
