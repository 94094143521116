import React from 'react';
import { Redirect, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { MyAccount } from './components/account/MyAccount';
import { AuthenticatedRoutingRule } from './components/commons/AuthenticatedRoutingRule';
import ConnectedNDCPage from './components/commons/NDCPage';
import { UnauthenticatedRoutingRule } from './components/commons/UnauthenticatedRoutingRule';
import ConnectedFamilyMemberUpdate from './components/dependent/FamilyMemberUpdate';
import ConnectedConsentToDoingBusiness from './components/electronicConsent/ConsentToDoingBusiness';
import ConnectedAcceptLoginToken from './components/login/AcceptLoginToken';
import ConnectedLocateMember from './components/login/LocateMember';
import { LoginRoutingRule } from './components/login/LoginRoutingRule';
import ConnectedRequestTokenDestination from './components/login/RequestTokenDestination';
import ConnectedLogout from './components/logout/Logout';
import ConnectedPaymentSurvey from './components/payments/PaymentSurvey';
import ConnectedTerminatedContractDashboard from './components/terminatedContract/NotActiveContractDashboard';
import {
  ACCOUNT_OVERVIEW,
  CANCELLED_OVERVIEW,
  CONSENT,
  FAMILY_UPDATE,
  LOGIN,
  LOGIN_SECURITY_CODE,
  LOGIN_VERIFY_SECURITY_CODE,
  LOGOUT, PAYMENT_HISTORY,
  PAYMENT_SUCCESS, PAYMENT_SURVEY,
} from './utils/Routes';
import ConnectedPaymentSuccess from './components/payments/PaymentSuccess';
import ConnectedPaymentHistory from './components/payments/PaymentHistory';

export const Routes = () => (
  <BrowserRouter>
    <ConnectedNDCPage>
      <Switch>
        <AuthenticatedRoutingRule
          exact
          title="Account Overview"
          path={ACCOUNT_OVERVIEW}
          component={MyAccount}
        />
        <AuthenticatedRoutingRule
          exact
          title="Payment History"
          path={PAYMENT_HISTORY}
          component={ConnectedPaymentHistory}
        />
        <AuthenticatedRoutingRule
          exact
          title="Account Overview"
          path={CANCELLED_OVERVIEW}
          component={ConnectedTerminatedContractDashboard}
        />
        <AuthenticatedRoutingRule
          exact
          title="Family"
          path={FAMILY_UPDATE}
          component={ConnectedFamilyMemberUpdate}
        />
        <AuthenticatedRoutingRule
          exact
          title="Payment Success"
          path={PAYMENT_SUCCESS}
          component={ConnectedPaymentSuccess}
        />
        <AuthenticatedRoutingRule
          exact
          title="Payment Survey"
          path={PAYMENT_SURVEY}
          component={ConnectedPaymentSurvey}
        />
        <LoginRoutingRule exact title="Login" path={LOGIN} component={ConnectedLocateMember} />
        <LoginRoutingRule
          exact
          title="Send Security Code"
          path={LOGIN_SECURITY_CODE}
          component={ConnectedRequestTokenDestination}
        />
        <LoginRoutingRule
          exact
          title="Verify Security Code"
          path={LOGIN_VERIFY_SECURITY_CODE}
          component={ConnectedAcceptLoginToken}
        />
        <UnauthenticatedRoutingRule exact title="Logout" path={LOGOUT} component={ConnectedLogout} />
        <UnauthenticatedRoutingRule
          exact
          title="Consent"
          path={CONSENT}
          component={ConnectedConsentToDoingBusiness}
        />
        <Redirect to={LOGIN} />
      </Switch>
    </ConnectedNDCPage>
  </BrowserRouter>)
;
