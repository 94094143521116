import PropTypes from 'prop-types';
import React from 'react';
import DateFormatter from '../commons/DateFormatter';

const PlanDetails = (props) => {
  const status = { 'Not Active': 'Expired', Active: 'Member since' };
  const statusDate = { 'Not Active': props.plan.endDate, Active: props.plan.startDate };
  return (
    <div>
      <b>{props.plan.type} plan</b> - {status[props.plan.status]}
      &nbsp;<DateFormatter date={statusDate[props.plan.status]} />
    </div>
  );
};

PlanDetails.propTypes = {
  plan: PropTypes.shape({
    endDate: PropTypes.string,
    startDate: PropTypes.string,
    type: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
};

export default PlanDetails;
