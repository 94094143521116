import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Route } from 'react-router';

export const UnauthenticatedRoutingRule = ({ component: Component, title, ...rest }) => (
  <span>
    <Helmet title={title} />
    <Route {...rest} render={matchProps => (<Component {...matchProps} />)} />
  </span>
);

UnauthenticatedRoutingRule.propTypes = {
  component: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
};
