export const LOGIN = '/login';
export const LOGIN_SECURITY_CODE = '/login/code';
export const LOGIN_VERIFY_SECURITY_CODE = '/login/verify';
export const LOGOUT = '/logout';
export const CONSENT = '/consent';
export const FAQ = 'https://www.newdentalchoice.com/faq';
export const ACCOUNT_OVERVIEW = '/myaccount/account-overview';
export const CANCELLED_OVERVIEW = '/myaccount/cancelled';
export const FAMILY_UPDATE = '/myaccount/family';
export const PAYMENT_SUCCESS = '/myaccount/payment/success';
export const PAYMENT_SURVEY = '/myaccount/payment/survey';
export const PAYMENT_HISTORY = '/myaccount/payment-history';
