import update from 'react-addons-update';

export const LOGIN_PAGE_LOADED = 'LOGIN_PAGE_LOADED';
export const VERIFIED_SECURITY_CODE = 'VERIFIED_SECURITY_CODE';
export const INCORRECT_SECURITY_CODE = 'INCORRECT_SECURITY_CODE';
export const UNAUTHENTICATED = 'UNAUTHENTICATED';
export const AUTHENTICATED = 'AUTHENTICATED';
export const SHOW_REQUEST_TOKEN = 'SHOW_REQUEST_TOKEN';
export const RESEND_SECURITY_CODE = 'RESEND_SECURITY_CODE';
export const SHOW_ACCEPT_TOKEN = 'SHOW_ACCEPT_TOKEN';
export const SEND_SECURITY_CODE_LOADING = 'SEND_SECURITY_CODE_LOADING';
export const SEND_TOKEN_FAILURE = 'SEND_TOKEN_FAILURE';
export const USER_NOT_FOUND = 'USER_NOT_FOUND';
export const APPLICATION_ERROR = 'APPLICATION_ERROR';
export const LOCATE_USER_LOADING = 'LOCATE_USER_LOADING';
export const VALIDATE_SECURITY_CODE_LOADING = 'VALIDATE_SECURITY_CODE_LOADING';
export const LOAD_CONSENT_INITIATED = 'LOAD_CONSENT_INITIATED';
export const LOAD_CONSENT_SUCCESS = 'LOAD_CONSENT_SUCCESS';
export const LOAD_CONSENT_FAILURE = 'LOAD_CONSENT_FAILURE';
export const AGREE_CONSENT_INITIATED = 'AGREE_CONSENT_INITIATED';
export const AGREE_CONSENT_SUCCESS = 'AGREE_CONSENT_SUCCESS';
export const AGREE_CONSENT_FAILURE = 'AGREE_CONSENT_FAILURE';

const initialState = {
  status: UNAUTHENTICATED,
  memberId: '',
  memberDob: '',
  hasApplicationError: false,
  hasError: false,
  isLocked: false,
  email: '',
  phoneNumber: '',
  deliverySMS: undefined,
  deliveryEmail: undefined,
  isLocateUserLoading: false,
  isSendSecurityCodeLoading: false,
  isValidateSecurityCodeLoading: false,
  isConsented: undefined,
};

const objectOrDefault = object => (object || {});

const handleAccountLocked = (response, state) => {
  if (response) {
    if (response.errorCode === 'account_locked') {
      return update(state, {
        isLocked: { $set: true },
        hasError: { $set: false },
        minutesLockedFor: { $set: response.minutesLockedFor },
      });
    }
  }
  return undefined;
};

const loginReducer = (state = initialState, { type, response }) => {
  const newState = update(state, {
    isLocateUserLoading: { $set: false },
    isSendSecurityCodeLoading: { $set: false },
    isValidateSecurityCodeLoading: { $set: false },
  });

  switch (type) {
    case APPLICATION_ERROR:
      return update(newState, {
        hasApplicationError: { $set: true },
        hasError: { $set: false },
        isLocked: { $set: false },
      });
    case RESEND_SECURITY_CODE:
      return update(newState, {
        status: { $set: SHOW_REQUEST_TOKEN },
        hasError: { $set: false },
        isLocked: { $set: false },
      });
    case SHOW_REQUEST_TOKEN:
      return update(newState, {
        status: { $set: type },
        email: { $set: response.emailAddress },
        phoneNumber: { $set: response.phoneNumber },
        deliverySMS: { $set: objectOrDefault(response._links.deliverySMS).href },
        deliveryEmail: { $set: objectOrDefault(response._links.deliveryEmail).href },
        hasError: { $set: false },
        isLocked: { $set: false },
      });
    case USER_NOT_FOUND: {
      const userNotFoundLockedState = handleAccountLocked(response, newState);
      return (userNotFoundLockedState === undefined) ? update(newState, {
        hasError: { $set: true },
        isLocked: { $set: false },
      }) : userNotFoundLockedState;
    }
    case SHOW_ACCEPT_TOKEN:
      return update(newState, {
        status: { $set: type },
        verifyTokenLink: { $set: response._links.validateSecurityCode.href },
        hasError: { $set: false },
        isLocked: { $set: false },
      });
    case SEND_TOKEN_FAILURE:
      return update(newState, { hasError: { $set: true } });
    case VERIFIED_SECURITY_CODE:
      return update(newState, {
        status: { $set: AUTHENTICATED },
        hasError: { $set: false },
        isLocked: { $set: false },
      });
    case INCORRECT_SECURITY_CODE: {
      const incorrectSecurityCodeLockedState = handleAccountLocked(response, newState);
      return (incorrectSecurityCodeLockedState === undefined) ? update(newState, {
        hasError: { $set: true },
        isLocked: { $set: false },
      }) : incorrectSecurityCodeLockedState;
    }
    case LOCATE_USER_LOADING:
      return update(newState, { isLocateUserLoading: { $set: true } });
    case SEND_SECURITY_CODE_LOADING:
      return update(newState, { isSendSecurityCodeLoading: { $set: true } });
    case VALIDATE_SECURITY_CODE_LOADING:
      return update(newState, { isValidateSecurityCodeLoading: { $set: true } });
    case LOAD_CONSENT_INITIATED:
      return update(newState, { isConsentLoading: { $set: true } });
    case LOAD_CONSENT_SUCCESS:
      return update(newState, {
        hasConsentErrors: { $set: false },
        isConsentLoading: { $set: false },
        isConsented: { $set: response.isConsented },
      });
    case LOAD_CONSENT_FAILURE:
      return update(newState, {
        hasConsentErrors: { $set: true },
        isConsentLoading: { $set: false },
      });
    case AGREE_CONSENT_INITIATED:
      return update(newState, { isAgreeConsentLoading: { $set: true } });
    case AGREE_CONSENT_SUCCESS:
      return update(newState, {
        hasAgreeConsentErrors: { $set: false },
        isAgreeConsentLoading: { $set: false },
        isConsented: { $set: true },
      });
    case AGREE_CONSENT_FAILURE:
      return update(newState, {
        hasAgreeConsentErrors: { $set: true },
        isAgreeConsentLoading: { $set: false },
      });
    default:
      return newState;
  }
};

export const getIsLocateUserLoading = state => state.login.isLocateUserLoading;
export const getIsSendSecurityCodeLoading = state => state.login.isSendSecurityCodeLoading;
export const getIsValidateSecurityCodeLoading = state => state.login.isValidateSecurityCodeLoading;
export const isUserAuthenticated = state => state.login.status === AUTHENTICATED;
export const status = state => state.login.status;
export const isAccountLocked = state => state.login.isLocked;
export const minutesLockedFor = state => state.login.minutesLockedFor;
export const isConsentLoading = state => state.login.isConsentLoading;
export const isAgreeConsentLoading = state => state.login.isAgreeConsentLoading;
export const hasConsentErrors = state => state.login.hasConsentErrors;
export const hasAgreeConsentErrors = state => state.login.hasAgreeConsentErrors;
export const isConsented = state => state.login.isConsented;
export const hasError = state => state.login.hasError;
export const hasApplicationError = state => state.login.hasApplicationError;
export const phone = state => state.login.phoneNumber;
export const email = state => state.login.email;
export const emailLink = state => state.login.deliveryEmail;
export const smsLink = state => state.login.deliverySMS;
export const verifySecurityCodeLink = state => state.login.verifyTokenLink;
export default loginReducer;
