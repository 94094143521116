import {
  PAYMENT_UPDATE_CLICKED,
  PAYMENT_UPDATE_FAILED,
  PAYMENT_UPDATE_LOADED,
  PAYMENT_UPDATE_LOADING,
} from '../../reducers/paymentUpdateReducer';
import { PAYMENT_UPDATE } from '../../utils/Links';

export const paymentUpdateClicked = () => ({ type: PAYMENT_UPDATE_CLICKED });

export const fetchPaymentUpdate = () => ({
  initiate: PAYMENT_UPDATE_LOADING,
  failure: PAYMENT_UPDATE_FAILED,
  loaded: PAYMENT_UPDATE_LOADED,
  urlName: PAYMENT_UPDATE,
});
