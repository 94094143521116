import PropTypes from 'prop-types';
import React from 'react';
import DateFormatter from '../commons/DateFormatter';
import PhoneNumberFormatter from '../commons/PhoneNumberFormatter';
import TitleFormatter from '../commons/TitleFormatter';

const Person = (props) => {
  const {
    firstName,
    lastName,
    streetAddress,
    streetAddress2,
    city,
    state,
    zipCode,
    dateOfBirth,
    emailAddress,
    phoneNumber,
  } = props.person || {};
  const fullStreetAddress = (streetAddress2)
    ? `${streetAddress} ${streetAddress2}`
    : streetAddress;

  return (
    <div className="person">
      {firstName && <div className="name"><b>{firstName} {lastName}</b></div>}
      {dateOfBirth && <div className="dateOfBirth"><DateFormatter date={dateOfBirth} /></div>}
      {streetAddress &&
      <div className="address"><TitleFormatter>{fullStreetAddress}</TitleFormatter></div>}
      {streetAddress &&
      <div className="cityState"><TitleFormatter>{city}</TitleFormatter>, {state} {zipCode}</div>}
      {emailAddress && <div className="email">{emailAddress}</div>}
      {phoneNumber && <div className="phone"><PhoneNumberFormatter>{phoneNumber}</PhoneNumberFormatter></div>}
    </div>
  );
};

Person.propTypes = {
  person: PropTypes.shape().isRequired,
};

export default Person;
