import PropTypes from 'prop-types';
import React from 'react';

export default class Form extends React.Component {
  getWrappedOnSubmit = onSubmit => (e) => {
    onSubmit();
    e.preventDefault();
  };

  render() {
    return (
      <form onSubmit={this.getWrappedOnSubmit(this.props.onSubmit)}>
        {this.props.children}
      </form>
    );
  }
}

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
};
