import PropTypes from 'prop-types';
import React from 'react';
import { FormGroup } from 'react-bootstrap';

export const ErrorFormGroup = (props) => {
  const { errors = [], property, children, ...rest } = props;
  const errorState = (errors.some(e => e.propertyName === property)) ? 'error' : null;
  return (<FormGroup {...rest} validationState={errorState}>{children}</FormGroup>);
};

ErrorFormGroup.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.shape({
    propertyName: PropTypes.string.isRequired,
  })).isRequired,
  property: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};
