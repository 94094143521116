import changeCase from 'change-case';
import PropTypes from 'prop-types';
import React from 'react';

const TitleFormatter = props =>
  (<span>{changeCase.title(props.children)}</span>);

TitleFormatter.propTypes = {
  children: PropTypes.element.isRequired,
};

export default TitleFormatter;

