import PropTypes from 'prop-types';
import React from 'react';
import { Panel } from 'react-bootstrap';
import { Spinner } from './Spinner';

export const LoadingPanel = props => (
  <Panel header={props.title}>
    <Spinner />
  </Panel>
);

LoadingPanel.propTypes = {
  title: PropTypes.string.isRequired,
};
