import getDataLoadReducer from '../../src/reducers/dataLoadReducer';

export const PAYMENT_HISTORY_LOADING = 'PAYMENT_HISTORY_LOADING';
export const PAYMENT_HISTORY_FAILED = 'PAYMENT_HISTORY_FAILED';
export const PAYMENT_HISTORY_LOADED = 'PAYMENT_HISTORY_LOADED';
export const PAYMENT_HISTORY_SEARCH_CLICKED = 'PAYMENT_HISTORY_SEARCH_CLICKED';
export const PAYMENT_HISTORY_PRINT_CLICKED = 'PAYMENT_HISTORY_PRINT_CLICKED';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';


export const isLoading = state => state.paymentHistory.isLoading;
export const hasError = state => state.paymentHistory.hasError;
export const getPaymentHistory = state => state.paymentHistory.resource.history;

const reducer = getDataLoadReducer(
  {
    resource: {
      history: [],
    },
    hasError: false,
    isLoading: false,
  },
  PAYMENT_HISTORY_LOADED,
  PAYMENT_HISTORY_LOADING,
  PAYMENT_HISTORY_FAILED);

export default reducer;
