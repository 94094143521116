import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import MaskedInput from 'react-text-mask';

const getDate = date => (moment(date, 'YYYY-MM-DD', true).isValid() ? moment(date).format('MM/DD/YYYY') : date);

export class DateField extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: getDate(this.props.date) };
  }

  componentDidMount() {
    window.jQuery('.date').datePicker();
  }

  onChange = (event) => {
    this.setState({ value: event.target.value });
    moment(event.target.value, 'MM/DD/YYYY', true).isValid() // eslint-disable-line no-unused-expressions
      ? this.props.update(moment(event.target.value, 'MM/DD/YYYY').format('YYYY-MM-DD'))
      : this.props.update('');
  };

  render() {
    const dateFieldClass = (this.state.value
                            && !moment(this.state.value, 'MM/DD/YYYY', true).isValid()
                            && this.state.value.replace(/\D/g, '').length === 8)
      ? 'has-error input-group date'
      : 'input-group date';

    return (<div className={dateFieldClass}>
      <MaskedInput
        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
        placeholder="MM/DD/YYYY"
        className="form-control"
        value={this.state.value ? this.state.value : ''}
        onChange={this.onChange}
        onPaste={this.onChange}
        onInput={this.onChange}
      />
      <span className="input-group-addon"><i className="fa fa-calendar" /></span>
    </div>);
  }
}

DateField.propTypes = {
  update: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
};
