/* eslint-disable react/jsx-filename-extension */
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import ResourceResolver from './middleware/ResourceResolverMiddleware';
import { rootReducer } from './reducers/rootReducer';
import { Routes } from './Routes';
import './styles/horizon.override.css';
import './styles/index.css';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer,
  composeEnhancers(
    applyMiddleware(thunk, ResourceResolver),
  ));

ReactDOM.render(<Provider store={store}><Routes /></Provider>, document.getElementById('root'));
