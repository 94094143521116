import PropTypes from 'prop-types';
import React from 'react';

const Alert = props => (
  <span>
    {props.errors && props.errors.map(error =>
      (<div key={error.propertyName} className="row">
        <div className="col-xs-12">
          <div className="alert alert-danger alert-icon" role="alert">{error.message}</div>
        </div>
      </div>))}
  </span>);

Alert.propTypes = {
  errors: PropTypes.shape({
    propertyName: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    map: PropTypes.func.isRequired,
  }).isRequired,
};

export default Alert;
