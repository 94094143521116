import PropTypes from 'prop-types';
import React from 'react';

const PlanStatus = props => (
  <span>
    {props.status !== '' && (props.status === 'Active'
      ? <span className="label label-success planStatus">Active</span>
      : <span className="label label-danger planStatus">Not active</span>)}
  </span>);

PlanStatus.defaultProps = {
  status: '',
};

PlanStatus.propTypes = {
  status: PropTypes.string,
};

export default PlanStatus;
