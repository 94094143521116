import { INCORRECT_SECURITY_CODE, VALIDATE_SECURITY_CODE_LOADING, VERIFIED_SECURITY_CODE } from '../../reducers/loginReducers';
import { post } from '../../utils/api';

const tokenVerified = () => ({
  type: VERIFIED_SECURITY_CODE,
});

const incorrectToken = response => ({
  type: INCORRECT_SECURITY_CODE,
  response,
});

export const verifyToken = (url, securityCode) => (dispatch) => {
  dispatch({ type: VALIDATE_SECURITY_CODE_LOADING });
  return post(url, { securityCode })
    .then(() => dispatch(tokenVerified()))
    .catch(e => dispatch(incorrectToken(e.response.data)));
};
