import React from 'react';
import { FormControl } from 'react-bootstrap';

export const RelationshipSelect = props => (
  <FormControl
    {...props}
    componentClass="select"
  >
    <option />
    <option>Spouse</option>
    <option>Child</option>
    <option>Other</option>
  </FormControl>
);
