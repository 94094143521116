import React from 'react';
import '../../styles/footer.css';
import { SupportPhoneNumber } from '../commons/SupportPhoneNumber';

const NDCFooter = () => {
  const termsOfUse = 'https://www.newdentalchoice.com/terms-of-use';
  const privacyPolicy = 'https://www.newdentalchoice.com/privacy';
  const contactUs = 'https://www.newdentalchoice.com/contact-us';
  const disclosures = 'https://www.newdentalchoice.com/description-of-services';
  return (
    <div>
      <div className="footer big-footer hidden-xs">
        <div className="spacer" />
        <div className="info">
          <div className="info-row">
            <i className="fa fa-phone" aria-hidden="true" />
            &nbsp;<SupportPhoneNumber /> (Se Habla Español)
            &nbsp;|&nbsp;<a href={contactUs} target="_blank" rel="noopener noreferrer">
              <i className="fa fa-envelope" aria-hidden="true" />
            &nbsp;Contact Us</a>
          </div>
          <div className="info-row">
            <a target="_blank" rel="noopener noreferrer" href={termsOfUse}>Terms of Use</a>
            &nbsp;|&nbsp;<a target="_blank" rel="noopener noreferrer" href={privacyPolicy}>Policies</a>
            &nbsp;|&nbsp;<a target="_blank" rel="noopener noreferrer" href={disclosures}>Disclosures</a>
          </div>
        </div>
      </div>
      <div className="footer small-footer visible-xs">
        <div className="spacer" />
        <div className="info">
          <div>
            <i className="fa fa-phone" aria-hidden="true" />
            <SupportPhoneNumber /><br /><b>(se habla español)</b><br />
            <a href={contactUs} target="_blank" rel="noopener noreferrer">Contact Us</a><br />
            <a target="_blank" rel="noopener noreferrer" href={termsOfUse}>Terms of Use</a><br />
            <a target="_blank" rel="noopener noreferrer" href={privacyPolicy}>Policies</a><br />
            <a target="_blank" rel="noopener noreferrer" href={disclosures}>Disclosures</a><br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NDCFooter;
