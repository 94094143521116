import React from 'react';
import URL from 'url-parse';

export default class EnvironmentHighlighter extends React.Component {
  render() {
    const environmentHighlighter = new URL(window.location.href).port === '3001'
      ? <hr className="port3001" />
      : null;
    return (
      environmentHighlighter
    );
  }
}
