import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Col, Row } from 'react-bootstrap';

export const LocateMemberMarketingPanel = () => (
  <span>
    <Row style={{ marginBottom: '20px' }}>
      <Col xs={12}>
        <h1>What you can do</h1>
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <Row style={{ marginBottom: '10px' }}>
          <Col xs={1}><FontAwesome name="eye" style={{ fontSize: '40px' }} /></Col>
          <Col xs={11}><h4 style={{ paddingLeft: '10px' }}>View your plan summary</h4></Col>
          <br />
        </Row>
        <Row style={{ marginBottom: '10px' }}>
          <Col xs={1}><FontAwesome name="pencil" style={{ fontSize: '40px' }} /></Col>
          <Col xs={11}><h4 style={{ paddingLeft: '10px' }}>Make changes to your account information</h4></Col>
          <br />
        </Row>
        <Row style={{ marginBottom: '10px' }}>
          <Col xs={1}><FontAwesome name="plus" style={{ fontSize: '40px' }} /></Col>
          <Col xs={11}><h4 style={{ paddingLeft: '10px' }}>Add or remove family members</h4></Col>
        </Row>
        <Row style={{ marginBottom: '10px' }}>
          <Col xs={1}><FontAwesome name="credit-card" style={{ fontSize: '40px' }} /></Col>
          <Col xs={11}><h4 style={{ paddingLeft: '10px' }}>Change your payment method, source and billing information</h4></Col>
        </Row>
        <Row style={{ marginBottom: '30px' }}>
          <Col xs={1}><FontAwesome name="print" style={{ fontSize: '40px' }} /></Col>
          <Col xs={11}><h4 style={{ paddingLeft: '10px' }}>View and print your membership card</h4></Col>
        </Row>
      </Col>
    </Row>
    <Row style={{ marginBottom: '30px' }}>
      <Col xs={12}>
        It’s that simple. When it comes to your New Dental Choice account, you’re in the driver’s seat.
      </Col>
    </Row>
    <Row >
      <Col xs={12}>
        Your opinions and ideas are important to us. Our goal is to continue to be your discount dental plan of choice.
        Please take a few minutes to complete our <a
          href="https://www.surveymonkey.com/r/PHCKCH3"
          target="_blank"
          rel="noreferrer"
        >Member Satisfaction Survey</a>.
      </Col>
    </Row>
  </span>
);
