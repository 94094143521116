import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { loadConsent } from '../../actions/login/ConsentAction';
import { isConsented, isConsentLoading } from '../../reducers/loginReducers';
import { CONSENT } from '../../utils/Routes';
import ConnectedRouteDirector from '../commons/RouteDirector';

export class HasConsentCheck extends Component {
  componentDidMount() {
    this.props.loadConsent();
  }

  getOn = () => (this.props.isConsented === undefined ? true : this.props.isLoading || this.props.isConsented);

  definition = { false: CONSENT };

  render() {
    return (
      <ConnectedRouteDirector
        history={this.props.history}
        on={this.getOn()}
        definition={this.definition}
      />
    );
  }
}

HasConsentCheck.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isConsented: PropTypes.bool.isRequired,
  history: PropTypes.shape.isRequired,
  loadConsent: PropTypes.func.isRequired,
};

export const mapStateToProps = state => ({
  isLoading: isConsentLoading(state),
  isConsented: isConsented(state),
});

export const mapDispatchToProps = dispatch => ({
  loadConsent: () => dispatch(loadConsent()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HasConsentCheck));
