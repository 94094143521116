import PropTypes from 'prop-types';
import React from 'react';
import { FormControl } from 'react-bootstrap';

export const TextField = props => (<FormControl
  {...props}
  value={props.value ? props.value : ''}
  type="text"
/>);

TextField.propTypes = {
  value: PropTypes.string.isRequired,
};
