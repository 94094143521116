import PropTypes from 'prop-types';
import React from 'react';

const ResendSecurityCodeModal = ({ resendSecurityCode }) => (
  <div className="modal fade">
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-header">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span
            aria-hidden="true"
          >&times;</span></button>
          <h2 className="modal-title">New Dental Choice secure and easy login</h2>
        </div>
        <div className="modal-body">
          <p>
            It might take a bit to receive your unique security code.
            If you’re still waiting, you may want to resend your request.
            Or if you requested an email, we suggest checking your spam or junk email folder.
            If you find the message there, be sure to mark it as “not spam” to help ensure
            future messages reach your inbox.
          </p>
          <p>
            Just click below if you’d like us to resend your security code.
            Or call customer support at 888-632-7558.
          </p>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-default"
            data-dismiss="modal"
            onClick={resendSecurityCode}
          >
            Resend security code
          </button>
          <button type="button" className="btn btn-primary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
);

ResendSecurityCodeModal.propTypes = {
  resendSecurityCode: PropTypes.func.isRequired,
};

export default ResendSecurityCodeModal;
