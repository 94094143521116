import PropTypes from 'prop-types';
import React from 'react';

const format7 = number => `${number.substr(0, 3)}-${number.substr(-4)}`;
const format10 = number => `(${number.substr(0, 3)}) ${number.substr(3, 3)}-${number.substr(-4)}`;
const format = (number) => {
  if (number.length === 7) {
    return format7(number);
  }
  return (number.length === 10) ? format10(number) : number;
};

const PhoneNumberFormatter = props =>
  (<span>{format(props.children)}</span>);

PhoneNumberFormatter.propTypes = {
  children: PropTypes.element.isRequired,
};

export default PhoneNumberFormatter;

