import PropTypes from 'prop-types';
import React from 'react';
import { isAlphaNumeric } from '../../utils/FormValidationUtils';
import { TextField } from './TextField';

export const AlphaNumericTextField = (props) => {
  const _handleAlphaInput = (e, onInput) => {
    if (isAlphaNumeric(e.target.value)) onInput(e);
  };
  return (
    <TextField
      {...props}
      onInput={e => _handleAlphaInput(e, props.onInput)}
      onPaste={e => _handleAlphaInput(e, props.onInput)}
    />
  );
};

AlphaNumericTextField.propTypes = {
  onInput: PropTypes.func.isRequired,
};
