import { MEMBER_CARD_DOWNLOAD, MEMBER_CARD_TOGGLE_SIDE, MEMBER_CARD_DETAILS_LOADED,
  MEMBER_CARD_DETAILS_LOADING, MEMBER_CARD_DETAILS_FAILED, CLEAR_MEMBER_CARD_DETAILS } from '../../reducers/memberCardReducer';
import { MEMBER_CARD_DETAILS } from '../../utils/Links';

export const toggleMemberCard = () => ({ type: MEMBER_CARD_TOGGLE_SIDE });
export const downloadMemberCard = () => ({ type: MEMBER_CARD_DOWNLOAD });
export const clearMemberCardDetails = () => ({ type: CLEAR_MEMBER_CARD_DETAILS });

export const fetchMemberCardDetails = () => ({
  urlName: MEMBER_CARD_DETAILS,
  loaded: MEMBER_CARD_DETAILS_LOADED,
  initiate: MEMBER_CARD_DETAILS_LOADING,
  failure: MEMBER_CARD_DETAILS_FAILED,
});
