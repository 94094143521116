import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ConnectedPlanSummary from '../../planSummary/PlanSummary';

export const TerminatedContractDashboard = () => (
  <Row>
    <Col xs={12}>
      <ConnectedPlanSummary />
    </Col>
  </Row>);
