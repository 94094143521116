import PropTypes from 'prop-types';
import React from 'react';
import { UnauthenticatedRoutingRule } from '../commons/UnauthenticatedRoutingRule';
import ConnectedLoginRouter from './LoginRouter';

export const LoginRoutingRule = props => (
  <div>
    <ConnectedLoginRouter />
    <UnauthenticatedRoutingRule {...props} />
  </div>);

LoginRoutingRule.propTypes = {
  component: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
};
