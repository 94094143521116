import PropTypes from 'prop-types';
import React from 'react';
import { Col, Panel, Row, Well } from 'react-bootstrap';
import '../../styles/unenrolledFamilyMembers.css';
import Submit from '../commons/Submit';

export const NotEnrolledFamilyMembers = props => (
  props.familyMembers.length > 0 &&
  (<Panel header="Not Enrolled Family Members">
    <Row>
      <Col xsOffset={1} xs={10}>
        <Row>
          {props.familyMembers.map(member => (
            <Col xs={12} sm={6}>
              <Well>
                <Row>
                  <Col className="text-center notEnrolledMemberName">
                    {member.firstName} {member.lastName}
                  </Col>
                </Row>
                <Row>
                  <Col className="text-center">
                    <Submit
                      isLoading={member.isReEnrolling}
                      onClick={() => props.reEnroll(member)}
                    >
                      Re-enroll
                    </Submit>
                  </Col>
                </Row>
              </Well>
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  </Panel>)

);

NotEnrolledFamilyMembers.propTypes = {
  familyMembers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};
