import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchPlanSummary } from '../../actions/planSummary/FetchSummaryAction';
import { getPlan } from '../../reducers/planSummaryReducers';
import { CANCELLED_OVERVIEW } from '../../utils/Routes';
import ConnectedRouteDirector from '../commons/RouteDirector';

export class HasTerminatedContractCheck extends Component {
  componentDidMount() {
    this.props.loadPlan();
  }

  definition = {
    'Not Active': CANCELLED_OVERVIEW,
    Pending: CANCELLED_OVERVIEW,
  };

  render() {
    return (
      <ConnectedRouteDirector
        history={this.props.history}
        on={this.props.plan.status}
        definition={this.definition}
      />
    );
  }
}

HasTerminatedContractCheck.propTypes = {
  plan: PropTypes.shape({ status: PropTypes.string.isRequired }).isRequired,
  history: PropTypes.shape.isRequired,
  loadPlan: PropTypes.func.isRequired,
};

export const mapStateToProps = state => ({
  plan: getPlan(state),
});

export const mapDispatchToProps = dispatch => ({
  loadPlan: () => dispatch(fetchPlanSummary()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HasTerminatedContractCheck);
