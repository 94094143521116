export const PLAN_SUMMARY = 'plandetails';
export const PAYMENT_SUMMARY = 'paymentdetails';
export const PAYMENT_UPDATE = 'paymentupdate';
export const PAYMENT_SURVEY = 'paymentsurvey';
export const PAYMENT_HISTORY = 'paymenthistory';
export const MEMBER_DETAILS = 'member';
export const LOGIN = 'login';
export const DEPENDENTS = 'dependents';
export const NOT_ENROLLED_DEPENDENTS = 'notEnrolledDependents';
export const OAUTH = 'oAuth';
export const VALIDATE_SESSION = 'validateSession';
export const STAY_LOGGED_IN = 'stayLoggedIn';
export const LOAD_CONSENT = 'loadConsent';
export const AGREE_CONSENT = 'agreeConsent';
export const DOWNLOAD_MEMBER_CARD = 'memberCard';
export const MEMBER_CARD_DETAILS = 'memberbase64encodedcard';
