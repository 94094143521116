
import { PAYMENT_HISTORY_FAILED,
  PAYMENT_HISTORY_LOADED,
  PAYMENT_HISTORY_LOADING,
  PAYMENT_HISTORY_SEARCH_CLICKED,
  PAYMENT_HISTORY_PRINT_CLICKED } from '../../reducers/paymentHistoryReducer';
import { PAYMENT_HISTORY } from '../../utils/Links';

export const paymentHistorySearchClicked = () => ({ type: PAYMENT_HISTORY_SEARCH_CLICKED });
export const paymentHistoryPrintClicked = () => ({ type: PAYMENT_HISTORY_PRINT_CLICKED });

export const fetchPaymentHistory = params => ({
  initiate: PAYMENT_HISTORY_LOADING,
  failure: PAYMENT_HISTORY_FAILED,
  loaded: PAYMENT_HISTORY_LOADED,
  urlName: PAYMENT_HISTORY,
  params,
});
