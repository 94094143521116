import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { fetchPlanSummary } from '../../actions/planSummary/FetchSummaryAction';
import { getPlan } from '../../reducers/planSummaryReducers';
import { ACCOUNT_OVERVIEW } from '../../utils/Routes';
import ConnectedRouteDirector from '../commons/RouteDirector';
import ConnectedMemberWelcome from '../planSummary/MemberWelcome';
import { PendingContractDashboard } from './Dashboards/PendingContractDashboard';
import { TerminatedContractDashboard } from './Dashboards/TerminatedContractDashboard';

export class NotActiveContractDashboard extends Component {
  componentDidMount() {
    this.props.loadPlan();
  }

  inactiveAlert = () => (<Alert bsStyle="warning">
    <p>Your account is no longer active.</p>
    <br />
    <p>
      Ready to reactivate your membership?
      <a href="https://www.newdentalchoice.com/join-new-dental-choice"> Join now</a>.
    </p>
  </Alert>);

  pendingAlert = () => (<Alert bsStyle="warning">
    <p>We don’t want you to miss out on valuable dental discounts through your New Dental Choice membership.
      &nbsp;So, please take a minute to update your payment information or provide a new form of payment.</p>
  </Alert>);

  definition = { Active: ACCOUNT_OVERVIEW };

  render() {
    return (
      <span>
        <ConnectedRouteDirector on={this.props.plan.status} definition={this.definition} />
        <Row>
          <Col xs={12}>
            <Row>
              <Col xs={12}>
                <ConnectedMemberWelcome />
              </Col>
            </Row>
            <Row>
              <Col>
                {this.props.plan.status === 'Pending' ? this.pendingAlert() : this.inactiveAlert()}
              </Col>
            </Row>
            {this.props.plan.status === 'Pending'
              ? <PendingContractDashboard />
              : <TerminatedContractDashboard />
            }
          </Col>
        </Row>
      </span>);
  }
}

NotActiveContractDashboard.propTypes = {
  loadPlan: PropTypes.func.isRequired,
  plan: PropTypes.shape({ status: PropTypes.string.isRequired }).isRequired,
};

export const mapStateToProps = state => ({
  plan: getPlan(state),
});

export const mapDispatchToProps = dispatch => ({
  loadPlan: () => dispatch(fetchPlanSummary()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NotActiveContractDashboard),
);
