import PropTypes from 'prop-types';
import React from 'react';

const PaymentMethod = ({ paymentMethod }) => {
  if (paymentMethod === undefined || (!paymentMethod.creditCard && !paymentMethod.bankAccount)) {
    return (
      <span>You currently have no recurring payment method set up.</span>);
  }

  return (
    <span>
      {paymentMethod.creditCard &&
      <span className="card">
        {paymentMethod.creditCard.cardType} - {paymentMethod.creditCard.cardNumberLast4}
      </span>
      }
      {paymentMethod.bankAccount &&
      <span className="ach">
        {paymentMethod.bankAccount.bankName ? paymentMethod.bankAccount.bankName : 'ACH'}
        &nbsp;- {paymentMethod.bankAccount.type} - {paymentMethod.bankAccount.accountNumberLast4}
      </span>}
    </span>
  );
};

PaymentMethod.propTypes = {
  paymentMethod: PropTypes.shape({
    creditCard: PropTypes.shape(),
    bankAccount: PropTypes.shape(),
  }).isRequired,
};

export default PaymentMethod;
