import update from 'react-addons-update';

const getDataLoadReducer = (defaultState, loaded, loading, failed) =>
  (state = defaultState, { type, response }) => {
    switch (type) {
      case loaded:
        return update(state,
          {
            resource: { $set: response },
            isLoading: { $set: false },
            hasError: { $set: false },
          });
      case failed:
        return update(state, { hasError: { $set: true }, isLoading: { $set: false } });
      case loading:
        return update(state, { hasError: { $set: false }, isLoading: { $set: true } });
      default:
        return state;
    }
  };

export default getDataLoadReducer;
