import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { paymentSuccess } from '../../actions/paymentSummary/PaymentSuccessAction';
import '../../styles/paymentSummary.css';
import { PAYMENT_SURVEY } from '../../utils/Routes';

export class PaymentSuccess extends React.Component {
  componentDidMount() {
    this.props.paymentSuccess();
    this.props.history.push(PAYMENT_SURVEY);
  }

  render() {
    return (<span />);
  }
}

PaymentSuccess.propTypes = {
  paymentSuccess: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export const mapDispatchToProps = dispatch => ({
  paymentSuccess: () => dispatch(paymentSuccess()),
});

export default withRouter(connect(() => ({}), mapDispatchToProps)(PaymentSuccess));
