import PropTypes from 'prop-types';
import React from 'react';
import { Prompt } from 'react-router';

export const HasChangesAlert = props =>
  (<Prompt
    when={props.hasChanges}
    message="You have unsaved information, are you sure you want to leave this page?"
  />);

HasChangesAlert.propTypes = {
  hasChanges: PropTypes.bool.isRequired,
};
