import { APPLICATION_ERROR, LOCATE_USER_LOADING, SHOW_REQUEST_TOKEN, USER_NOT_FOUND } from '../../reducers/loginReducers';
import { post } from '../../utils/api';
import { LOGIN, OAUTH } from '../../utils/Links';

const fetchUserDetailsAction = params => ({
  urlName: LOGIN,
  loaded: SHOW_REQUEST_TOKEN,
  initiate: LOCATE_USER_LOADING,
  failure: USER_NOT_FOUND,
  params,
});

const determineWhichActionToDispatchFrom = (response) => {
  if (response && response.status === 401) {
    return USER_NOT_FOUND;
  }
  return APPLICATION_ERROR;
};

export const retrieveOAuth = (url, params) => dispatch => post(url, params)
  .then(() => dispatch(fetchUserDetailsAction(params)))
  .catch((error) => {
    const actionToDispatch = determineWhichActionToDispatchFrom(error.response);
    dispatch({ type: actionToDispatch, response: error.response ? error.response.data : {} });
  });

const fetchOAuth = params => ({
  urlName: OAUTH,
  fetch: retrieveOAuth,
  initiate: LOCATE_USER_LOADING,
  failure: USER_NOT_FOUND,
  params,
});

export const fetchUserDetails = params => dispatch => dispatch(fetchOAuth(params));
