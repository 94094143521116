import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, ControlLabel, Panel, Row } from 'react-bootstrap';
import { isNew } from '../../utils/newId.service';
import Alert from '../commons/Alert';
import { DateField } from '../commons/DateField';
import { ErrorFormGroup } from '../commons/ErrorFormGroup';
import Form from '../commons/Form';
import { GenderSelect } from '../commons/GenderSelect';
import { StateCodeSelect } from '../commons/StateCodeSelect';
import { RelationshipSelect } from '../commons/RelationshipSelect';
import Submit from '../commons/Submit';
import { TextField } from '../commons/TextField';
import ConnectedFamilyMemberRemoveConfirmationModal from '../dependent/FamilyMemberRemoveConfirmationModal';

export class FamilyMemberForm extends React.Component {
  save = props => () => props.save(props.person);

  toggleModal = props => () => props.toggleModal(props.person.id);

  updateDate = property =>
    value => this.props.updateFamilyMember(property, value, this.props.person.id);

  updateProperty = property =>
    event => this.props.updateFamilyMember(property, event.target.value, this.props.person.id);

  render() {
    const {
      id,
      firstName,
      lastName,
      streetAddress,
      streetAddress2,
      city,
      state,
      zipCode,
      dateOfBirth,
      gender,
      relationshipType,
      hasChanges,
      isSaving,
      errors,
    } = this.props.person || {};
    return (<span>
      <Form onSubmit={this.save(this.props)}>
        <Panel header="Family member">
          <Alert errors={errors} />
          <Row>
            <Col sm={12} md={6}>
              <ErrorFormGroup errors={errors} property="firstName">
                <ControlLabel>First name</ControlLabel>
                <TextField
                  className="first-name"
                  value={firstName}
                  onChange={this.updateProperty('firstName')}
                />
              </ErrorFormGroup>
            </Col>
            <Col sm={12} md={6}>
              <ErrorFormGroup errors={errors} property="lastName">
                <ControlLabel>Last name</ControlLabel>
                <TextField
                  className="last-name"
                  value={lastName}
                  onChange={this.updateProperty('lastName')}
                />
              </ErrorFormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={4}>
              <ErrorFormGroup errors={errors} property="dateOfBirth">
                <ControlLabel>Date of birth</ControlLabel>
                <DateField date={dateOfBirth} update={this.updateDate('dateOfBirth')} />
              </ErrorFormGroup>
            </Col>
            <Col sm={6} md={4}>
              <ErrorFormGroup errors={errors} property="relationshipType">
                <ControlLabel>Relationship</ControlLabel>
                <RelationshipSelect
                  value={relationshipType}
                  onChange={this.updateProperty('relationshipType')}
                />
              </ErrorFormGroup>
            </Col>
            <Col sm={6} md={4}>
              <ErrorFormGroup errors={errors} property="gender">
                <ControlLabel>Gender</ControlLabel>
                <GenderSelect
                  value={gender}
                  onChange={this.updateProperty('gender')}
                />
              </ErrorFormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} lg={3}>
              <ErrorFormGroup errors={errors} property="streetAddress">
                <ControlLabel>Street address</ControlLabel>
                <TextField
                  className="street"
                  value={streetAddress}
                  onChange={this.updateProperty('streetAddress')}
                />
              </ErrorFormGroup>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <ErrorFormGroup errors={errors} property="streetAddress2">
                <ControlLabel>Street address 2</ControlLabel>
                <TextField
                  className="street2"
                  value={streetAddress2}
                  onChange={this.updateProperty('streetAddress2')}
                />
              </ErrorFormGroup>
            </Col>
            <Col sm={12} md={4} lg={2}>
              <ErrorFormGroup errors={errors} property="city">
                <ControlLabel>City</ControlLabel>
                <TextField
                  className="city"
                  value={city}
                  onChange={this.updateProperty('city')}
                />
              </ErrorFormGroup>
            </Col>
            <Col sm={12} md={4}>
              <ErrorFormGroup errors={errors} property="state">
                <ControlLabel>State</ControlLabel>
                <StateCodeSelect
                  value={state}
                  onChange={this.updateProperty('state')}
                />
              </ErrorFormGroup>
            </Col>
            <Col sm={12} md={4} lg={2}>
              <ErrorFormGroup errors={errors} property="zipCode">
                <ControlLabel>Zip code</ControlLabel>
                <TextField
                  className="zipCode"
                  value={zipCode}
                  onChange={this.updateProperty('zipCode')}
                />
              </ErrorFormGroup>
            </Col>
          </Row>
          <div className="row">
            <div className="btn-toolbar col-sm-12">
              <Submit
                className="btn btn-primary pull-right save
                col-xs-4 col-sm-3 col-md-2 col-lg-2 col-xl-1"
                disabled={!hasChanges}
                isLoading={isSaving}
              >Save</Submit>
              {!isNew(id) &&
              <Button
                className="btn btn-default pull-right remove
                 col-xs-4 col-sm-4 col-md-4 col-lg-3 col-xl-2"
                onClick={this.toggleModal(this.props)}
              >Remove</Button>}
            </div>
          </div>
        </Panel>
      </Form>
      <ConnectedFamilyMemberRemoveConfirmationModal person={this.props.person} />
    </span>);
  }
}

FamilyMemberForm.propTypes = {
  updateFamilyMember: PropTypes.func.isRequired,
  person: PropTypes.shape().isRequired,
};
