import * as React from 'react';
import PropTypes from 'prop-types';

export default class Print extends React.Component {
  print = () => {
    this.props.onClick();
    window.print();
  }
  render() {
    return (
      <span
        role="link"
        tabIndex="0"
        onClick={this.print}
        style={{ cursor: 'pointer' }}
      ><i className="fa fa-print" aria-hidden="true" />&nbsp;Print</span>
    );
  }
}

Print.propTypes = {
  onClick: PropTypes.func.isRequired,
};
