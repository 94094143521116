import getDataLoadReducer from '../../src/reducers/dataLoadReducer';

export const PAYMENT_DETAILS_LOADING = 'PAYMENT_DETAILS_LOADING';
export const PAYMENT_DETAILS_FAILED = 'PAYMENT_DETAILS_FAILED';
export const PAYMENT_DETAILS_LOADED = 'PAYMENT_DETAILS_LOADED';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';


export const isLoading = state => state.payment.isLoading;
export const hasError = state => state.payment.hasError;
export const getPaymentAmount = state => state.payment.resource.nextPayment.amount;
export const getPaymentWithdrawDate = state => state.payment.resource.nextPayment.withdrawDate;
export const getPaymentMethod = state => state.payment.resource.method;

const reducer = getDataLoadReducer(
  {
    resource: {
      nextPayment: {},
    },
    hasError: false,
    isLoading: false,
  },
  PAYMENT_DETAILS_LOADED,
  PAYMENT_DETAILS_LOADING,
  PAYMENT_DETAILS_FAILED);

export default reducer;
