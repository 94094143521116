import { combineReducers } from 'redux';
import { allReducers } from './allReducers';

export const LOGOUT = 'LOGOUT';

export const rootReducer = (state, action) => {
  let realState = state;
  if (action.type === LOGOUT) {
    realState = undefined;
  }

  return combineReducers(allReducers())(realState, action);
};
