import PropTypes from 'prop-types';
import React from 'react';
import ndcLogo from './LogoFront.png';
import { PRIMARY } from '../../properties/colors';
import './memberCardFront.css';

export const MemberCardFront = ({ contractId, firstName, lastName, startDate }) => (
  <div style={{ border: '1px solid #464646', borderRadius: '25px', padding: '15px', fontSize: '.7em' }}>
    <img className="img-responsive logo bottomPad" src={ndcLogo} alt="New Dental Choice Logo" />
    <table className="bottomPad">
      <tr>
        <td className="member-card-label">Membership ID#:</td>
        <td>{contractId}</td>
      </tr>
      <tr>
        <td>Member name:</td>
        <td>{firstName} {lastName}</td>
      </tr>
      <tr>
        <td>Effective date:</td>
        <td>{startDate}</td>
      </tr>
    </table>
    <span style={{ fontSize: '.9em' }}>
      <div>For eligibility questions, click or call:</div>
      <div style={{ color: PRIMARY }}><strong>www.newdentalchoice.com/eligibility | (888) 632-8585</strong></div>
      <div>For membership questions, click or call:</div>
      <div className="bottomPad" style={{ color: PRIMARY }}><strong>www.newdentalchoice.com | (888) 632-3676</strong></div>
      <div>
        <strong>New Dental Choice is NOT insurance. </strong>
        <small>It is a discount dental plan provided by First Dental Health.</small>
      </div>
    </span>
    <div>
      <small><small>062916MC</small></small>
    </div>
  </div>
);

MemberCardFront.propTypes = {
  contractId: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
};
