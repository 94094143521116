import Moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

const DateFormatter = props => (
  <span>{Moment(props.date, 'YYYY/MM/DD').isValid() && Moment(props.date, 'YYYY/MM/DD').format('MMMM D, YYYY')}</span>
);

DateFormatter.propTypes = {
  date: PropTypes.string.isRequired,
};

export default DateFormatter;
