import PropTypes from 'prop-types';
import React from 'react';
import { Link } from '../../components/commons/Link';
import { goTo } from '../../utils/Location.service';

export default class NavBarItem extends React.Component {
  onClick = (event) => {
    event.target.blur();
    const route = this.props.navItem.route;
    if (route.startsWith('http')) {
      goTo(route);
    } else {
      this.props.push(route);
    }
  };

  render() {
    const activeClass = this.props.pathname === this.props.navItem.route ? 'active' : 'inactive';
    const positionClass = this.props.navItem.position === 'right' ? 'navbar-right' : '';
    return (
      <ul className={'nav navbar-nav '.concat(positionClass)}>
        <li className={activeClass}>
          <Link route={this.props.navItem.route}>
            {this.props.navItem.label}
          </Link>
        </li>
      </ul>
    );
  }
}

NavBarItem.propTypes = {
  push: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
  navItem: PropTypes.shape({
    route: PropTypes.string,
    position: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
};
