import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { logout, stayLoggedIn, validateSession } from '../../actions/logout/LogoutActions';
import { within2Minutes } from '../../reducers/validateSessionReducer';
import '../../styles/logoutWarningModal.css';
import { start } from '../../utils/interval';
import { LOGOUT } from '../../utils/Routes';

export class LogoutWarningModal extends Component {
  componentDidMount() {
    this.validateSessionInterval = start(this.props.validateSession, 30);
  }

  componentWillUnmount() {
    this.validateSessionInterval.stop();
  }

  validateSessionInterval = {};

  render() {
    return (
      <span>
        {this.props.within2Minutes &&
        <div className="modal" style={{ display: 'block' }} aria-hidden="true">
          <div className="modal-dialog modal-sm logoutWarningModal">
            <div className="modal-content logoutWarningModal">
              <div className="modal-header">Still there? You&apos;ve been inactive for a while. To protect
                your account you&apos;ll be logged out shortly.
              </div>
              <div className="modal-footer">
                <Button
                  className="btn btn-primary btn-block stay-logged-in-button"
                  onClick={this.props.stayLoggedIn}
                >Stay Logged In</Button>
                <Button
                  className="btn btn-default btn-block log-out-button"
                  onClick={() => this.props.router.push(LOGOUT)}
                >Log out</Button>
              </div>
            </div>
          </div>
        </div>}
      </span>
    );
  }
}

LogoutWarningModal.propTypes = {
  validateSession: PropTypes.func.isRequired,
  within2Minutes: PropTypes.bool.isRequired,
  stayLoggedIn: PropTypes.func.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export const mapStateToProps = state => ({
  within2Minutes: within2Minutes(state),
});

export const mapDispatchToProps = dispatch => ({
  stayLoggedIn: () => dispatch(stayLoggedIn()),
  logout: () => dispatch(logout()),
  validateSession: () => dispatch(validateSession()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LogoutWarningModal);
