import update from 'react-addons-update';
import getDataLoadReducer from './dataLoadReducer';
import { getId, isNew } from '../utils/newId.service';
import { DEPENDENTS_RE_ENROLL_SUCCESS } from './notEnrolledDependentsReducer';

export const FAMILY_MEMBER_ADD = 'FAMILY_MEMBER_ADD';
export const FAMILY_MEMBER_UPDATE_PROPERTY = 'FAMILY_MEMBER_UPDATE_PROPERTY';
export const DEPENDENTS_LOADING = 'DEPENDENTS_LOADING';
export const DEPENDENTS_LOADED = 'DEPENDENTS_LOADED';
export const DEPENDENTS_FAILED = 'DEPENDENTS_FAILED';
export const DEPENDENTS_NEW_INITIATED = 'DEPENDENTS_NEW_INITIATED';
export const DEPENDENTS_NEW_FAILED = 'DEPENDENTS_NEW_FAILED';
export const DEPENDENTS_NEW_SUCCESS = 'DEPENDENTS_NEW_SUCCESS';
export const DEPENDENTS_UPDATE_INITIATED = 'DEPENDENTS_UPDATE_INITIATED';
export const DEPENDENTS_UPDATE_FAILED = 'DEPENDENTS_UPDATE_FAILED';
export const DEPENDENTS_UPDATE_SUCCESS = 'DEPENDENTS_UPDATE_SUCCESS';
export const DEPENDENTS_REMOVE_INITIATED = 'DEPENDENTS_REMOVE_INITIATED';
export const DEPENDENTS_REMOVE_SUCCESS = 'DEPENDENTS_REMOVE_SUCCESS';
export const DEPENDENTS_REMOVE_FAILED = 'DEPENDENTS_REMOVE_FAILED';
export const DEPENDENTS_TOGGLE_MODAL = 'DEPENDENTS_TOGGLE_MODAL';

export const getDependentsFrom =
  state => ((state.dependent) ? state.dependent.resource.dependents : []);
export const getSaveNewFamilyMemberUrl =
  state => state.dependent.resource._links.saveNewFamilyMember.href;
export const isLoading =
  state => state.dependent.isLoading;
export const hasError =
  state => state.dependent.hasError;
export const anyFamilyChanges =
  state => state.dependent.resource.dependents.some(dependent => dependent.hasChanges);
export const isLastFamilyMember =
  state => state.dependent.resource.dependents.filter(d => !isNew(d.id)).length < 2;

const dataReducer = getDataLoadReducer(
  {
    resource: { dependents: [], _links: { saveNewFamilyMember: { href: '' } } },
    isLoading: false,
    hasError: false,
  },
  DEPENDENTS_LOADED,
  DEPENDENTS_LOADING,
  DEPENDENTS_FAILED);

const getIndex =
  (state, action) => state.resource.dependents.findIndex(dependent => dependent.id === action.key);

const initializeNewFamilyMemberWith = id => ({
  id,
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  streetAddress: '',
  city: '',
  state: '',
  zipCode: '',
  relationshipType: '',
});

const reducer = (state, action) => {
  switch (action.type) {
    case FAMILY_MEMBER_ADD:
      return update(state,
        {
          resource: {
            dependents: {
              $push: [initializeNewFamilyMemberWith(getId(state.resource.dependents))],
            },
          },
        });
    case FAMILY_MEMBER_UPDATE_PROPERTY:
      return update(state, {
        resource: {
          dependents: {
            [getIndex(state, action)]: {
              [action.property]: { $set: action.value },
              hasChanges: { $set: true },
            },
          },
        },
      });
    case DEPENDENTS_UPDATE_SUCCESS:
    case DEPENDENTS_NEW_SUCCESS:
      return update(state, {
        resource: {
          dependents: {
            [getIndex(state, action)]: {
              $set: action.resource,
            },
          },
        },
      });
    case DEPENDENTS_UPDATE_INITIATED:
    case DEPENDENTS_NEW_INITIATED:
      return update(state, {
        resource: {
          dependents: {
            [getIndex(state, action)]: {
              isSaving: { $set: true },
            },
          },
        },
      });
    case DEPENDENTS_UPDATE_FAILED:
    case DEPENDENTS_NEW_FAILED:
      return update(state, {
        resource: {
          dependents: {
            [getIndex(state, action)]: {
              errors: { $set: action.resource.errors },
              isSaving: { $set: false },
            },
          },
        },
      });
    case DEPENDENTS_REMOVE_INITIATED:
      return update(state, {
        resource: {
          dependents: {
            [getIndex(state, action)]: {
              isRemoving: { $set: true },
            },
          },
        },
      });
    case DEPENDENTS_REMOVE_SUCCESS: {
      const index = getIndex(state, action);
      return update(state, {
        resource: {
          dependents: { $splice: [[index, 1]] },
        },
      });
    }
    case DEPENDENTS_REMOVE_FAILED:
      return update(state, {
        resource: {
          dependents: {
            [getIndex(state, action)]: {
              removeErrors: { $set: action.resource.errors },
              isRemoving: { $set: false },
            },
          },
        },
      });
    case DEPENDENTS_RE_ENROLL_SUCCESS:
      return update(state, {
        resource: {
          dependents: {
            $push: [action.resource],
          },
        },
      });
    case DEPENDENTS_TOGGLE_MODAL:
      return update(state, {
        resource: {
          dependents: {
            [getIndex(state, action)]: {
              showRemoveModal: { $apply: currentValue => !currentValue },
            },
          },
        },
      });
    default:
      return dataReducer(state, action);
  }
};

export default reducer;
