import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { AUTHENTICATED, SHOW_ACCEPT_TOKEN, SHOW_REQUEST_TOKEN, status, UNAUTHENTICATED } from '../../reducers/loginReducers';
import { CONSENT, LOGIN, LOGIN_SECURITY_CODE, LOGIN_VERIFY_SECURITY_CODE } from '../../utils/Routes';
import ConnectedRouteDirector from '../commons/RouteDirector';

const loginRoutes = {
  [AUTHENTICATED]: CONSENT,
  [UNAUTHENTICATED]: LOGIN,
  [SHOW_REQUEST_TOKEN]: LOGIN_SECURITY_CODE,
  [SHOW_ACCEPT_TOKEN]: LOGIN_VERIFY_SECURITY_CODE,
};

const LoginRouter = ({ loginStatus }) => (<ConnectedRouteDirector on={loginStatus} definition={loginRoutes} />);

export const mapStateToProps = state => ({
  loginStatus: status(state),
});

LoginRouter.propTypes = {
  loginStatus: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, () => ({}))(LoginRouter);
