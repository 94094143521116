import PropTypes from 'prop-types';
import React from 'react';
import { Panel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { fetchPaymentSummary } from '../../actions/paymentSummary/FetchPaymentSummaryAction';
import { getPaymentAmount, getPaymentMethod, getPaymentWithdrawDate, hasError, isLoading } from '../../reducers/paymentSummaryReducers';
import '../../styles/paymentSummary.css';
import DateFormatter from '../commons/DateFormatter';
import { FetchPanel } from '../commons/FetchPanel';
import PaymentMethod from './PaymentMethod';
import PaymentMethodUpdate from './PaymentMethodUpdate';
import { PAYMENT_HISTORY } from '../../utils/Routes';

export class PaymentSummary extends React.Component {
  componentDidMount() {
    this.props.loadPaymentSummary();
  }

  render() {
    const footer = <Link to={PAYMENT_HISTORY}>Payment History &#xf105;</Link>;

    return (
      <FetchPanel isLoading={this.props.isLoading} title="Payments" hasError={this.props.hasError}>
        <Panel header="Payments" footer={footer}>
          <span className="text-center">
            <div className="paymentAmount">
              <NumberFormat
                value={this.props.paymentAmount}
                displayType={'text'}
                decimalPrecision={2}
                prefix={'$'}
                thousandSeparator
              />
            </div>
            <div>
              The next payment is due on
            </div>
            <div className="paymentDueDate">
              <DateFormatter date={this.props.paymentWithdrawDate} />
            </div>
            <div className="paymentMethod">
              <PaymentMethod paymentMethod={this.props.paymentMethod} />
            </div>
            <PaymentMethodUpdate />
          </span>
        </Panel>
      </FetchPanel>);
  }
}

PaymentSummary.propTypes = {
  loadPaymentSummary: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  paymentAmount: PropTypes.string.isRequired,
  paymentWithdrawDate: PropTypes.string.isRequired,
  paymentMethod: PropTypes.shape().isRequired,
};

export const mapStateToProps = state => ({
  isLoading: isLoading(state),
  hasError: hasError(state),
  paymentAmount: getPaymentAmount(state),
  paymentWithdrawDate: getPaymentWithdrawDate(state),
  paymentMethod: getPaymentMethod(state),
});

export const mapDispatchToProps = dispatch => ({
  loadPaymentSummary: () => {
    dispatch(fetchPaymentSummary());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSummary);
