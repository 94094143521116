import {
  MEMBER_UPDATE_FAILED,
  MEMBER_UPDATE_INITIATED,
  MEMBER_UPDATE_PROPERTY,
  MEMBER_UPDATE_SUCCESS,
  UPDATED_REQUIRED_INFORMATION_SUCCESS,
} from '../../reducers/memberDetailsReducer';
import { patch } from '../../utils/api';

const updateSuccess = (success, dispatch) => ({ data }) => {
  if (success) success(dispatch);
  dispatch({ type: MEMBER_UPDATE_SUCCESS, resource: data });
};

const updateFail = dispatch => ({ response }) => {
  dispatch({ type: MEMBER_UPDATE_FAILED, resource: response.data });
};

const updateMember = (person, success) => (dispatch) => {
  dispatch({ type: MEMBER_UPDATE_INITIATED });
  return patch(person._links.update.href, person)
    .then(updateSuccess(success, dispatch))
    .catch(updateFail(dispatch));
};

export const saveRequiredInformation = person =>
  updateMember(person, dispatch => dispatch({ type: UPDATED_REQUIRED_INFORMATION_SUCCESS }));

export const updateMemberProperty = (property, value) => ({
  type: MEMBER_UPDATE_PROPERTY, property, value,
});

export const update = person => updateMember(person);
