import PropTypes from 'prop-types';
import React from 'react';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';

export const RadioButtons = (props) => {
  const handleClickYes = () => {
    props.click('Y');
  };

  const handleClickNo = () => {
    props.click('N');
  };

  return (
    <ToggleButtonGroup type="radio" name="radioButtons">
      <ToggleButton onClick={handleClickYes} value={''}>Yes</ToggleButton>
      <ToggleButton onClick={handleClickNo} value={'No'}>No</ToggleButton>
    </ToggleButtonGroup>
  );
};

RadioButtons.propTypes = {
  click: PropTypes.func.isRequired,
};
