import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { logout, logoutAction } from '../../actions/logout/LogoutActions';
import { ACCOUNT_OVERVIEW, LOGIN } from '../../utils/Routes';
import { LoadingPanel } from '../commons/LoadingPanel';

export class Logout extends React.Component {
  componentDidMount() {
    this.props.logout();
    logout()
      .then(() => this.gotoLogin())
      .catch((e) => {
        if (e.response.status !== 401) this.gotoAccount();
        else this.gotoLogin();
      });
  }

  gotoAccount() {
    this.props.history.push(ACCOUNT_OVERVIEW);
  }

  gotoLogin() {
    this.props.history.push(LOGIN);
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-6 col-md-offset-3 col-xs-12">
          <LoadingPanel title="Please wait while you are being securely logged out." />
        </div>
      </div>
    );
  }
}

Logout.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logoutAction()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Logout));
