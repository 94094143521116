import update from 'react-addons-update';
import { OAUTH } from '../utils/Links';

export const STORE_LINKS = 'STORE_LINKS';
const defaultLinks = { [OAUTH]: '/api/oauth/token' };
const initialState = {
  links: defaultLinks,
};

function extractLinksFrom(response) {
  return Object.keys(response._links).reduce((links, link) => {
    const newLinks = links;
    newLinks[link] = response._links[link].href;
    return newLinks;
  }, { [OAUTH]: '/api/oauth/token' });
}

const linksReducer = (state = initialState, { type, response }) => {
  switch (type) {
    case STORE_LINKS:
      return update(state, { links: { $set: extractLinksFrom(response) } });
    default:
      return state;
  }
};

export const getLink = (state, link) => state.links.links[link];

export default linksReducer;
