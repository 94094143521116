import dependentReducer from './dependentsReducer';
import linksReducer from './linksReducer';
import loginReducer from './loginReducers';
import memberCardReducer from './memberCardReducer';
import memberDetailsReducer from './memberDetailsReducer';
import notEnrolledDependentsReducer from './notEnrolledDependentsReducer';
import paymentReducer from './paymentSummaryReducers';
import paymentUpdateReducer from './paymentUpdateReducer';
import paymentHistoryReducer from './paymentHistoryReducer';
import planSummaryReducer from './planSummaryReducers';
import validateSessionReducer from './validateSessionReducer';

export const allReducers = () => ({
  login: loginReducer,
  links: linksReducer,
  plan: planSummaryReducer,
  member: memberDetailsReducer,
  dependent: dependentReducer,
  payment: paymentReducer,
  paymentUpdate: paymentUpdateReducer,
  paymentHistory: paymentHistoryReducer,
  validateSession: validateSessionReducer,
  notEnrolledDependents: notEnrolledDependentsReducer,
  memberCard: memberCardReducer,
});
