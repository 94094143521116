import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import { NavItems } from './NavItems';
import EnvironmentHighlighter from './EnvironmentHighlighter';
import NavBar from './NavBar';

export const NDCHeader = props => (
  <span>
    <NavBar pathname={props.location.pathname} push={props.history.push} navItems={NavItems} />
    <EnvironmentHighlighter />
  </span>
);

NDCHeader.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(NDCHeader);
