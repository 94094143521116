import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import {
  addFamilyMember,
  fetchDependents,
  fetchNotEnrolledDependents,
  reEnrollFamilyMember,
  saveNewFamilyMember,
  saveUpdatedFamilyMember,
  toggleModal,
  updateFamilyMemberProperty,
} from '../../actions/memberDetails/DependentsActions';
import { fetchMemberDetails } from '../../actions/memberDetails/FetchMemberAction';
import { updateMemberProperty } from '../../actions/memberDetails/UpdateMemberAction';
import { anyFamilyChanges, getDependentsFrom, getSaveNewFamilyMemberUrl, hasError, isLoading } from '../../reducers/dependentsReducer';
import { getMember, isLoading as isMemberLoading } from '../../reducers/memberDetailsReducer';
import { dependents } from '../../reducers/notEnrolledDependentsReducer';
import '../../styles/planSummary.css';
import { isNew } from '../../utils/newId.service';
import { FetchPanel } from '../commons/FetchPanel';
import { HasChangesAlert } from '../commons/HasChangesAlert';
import { FamilyMemberForm } from './FamilyMemberForm';
import { MemberForm } from './MemberForm';
import { NotEnrolledFamilyMembers } from './UnenrolledFamilyMembers';

export class FamilyMemberUpdate extends React.Component {
  componentDidMount() {
    this.props.fetchDependents();
    this.props.fetchNotEnrolledDependents();
    this.props.fetchMember();
  }

  isFamilyMemberType = () => this.props.dependents.length !== 0;

  saveNewFamilyMember =
    dependent => this.props.saveNewFamilyMember(this.props.saveFamilyMemberUrl, dependent);

  saveUpdatedFamilyMember = dependent => this.props.saveUpdatedFamilyMember(dependent);

  saveMember = dependent => ((isNew(dependent.id) && dependent.relationshipType !== 'Member')
    ? this.saveNewFamilyMember(dependent)
    : this.saveUpdatedFamilyMember(dependent));

  render() {
    return (
      <span>
        <HasChangesAlert hasChanges={this.props.anyFamilyChanges} {...this.props} />
        <div className="row">
          <div className="col-xs-12">
            <FetchPanel
              title={'Family Member'}
              isLoading={this.props.isLoading}
              hasError={this.props.hasError}
            >
              <span>
                <MemberForm
                  person={this.props.member}
                  updateFamilyMember={this.props.updateMember}
                  save={this.saveMember}
                />

                <span className="family-members">
                  {this.props.dependents.map(dependent => (<FamilyMemberForm
                    person={dependent}
                    key={dependent.contactId}
                    updateFamilyMember={this.props.updateFamilyMember}
                    save={this.saveMember}
                    toggleModal={this.props.toggleModal}
                  />))
                  }
                </span>

                <NotEnrolledFamilyMembers
                  familyMembers={this.props.notEnrolledDependents}
                  reEnroll={this.props.reEnroll}
                />

                {this.isFamilyMemberType() &&
                <button
                  id="addFamilyBtn"
                  className="btn btn-default pull-right"
                  onClick={this.props.addFamilyMember}
                >
                  <i className="fa fa-plus" /> New family member
                </button>}
              </span>
            </FetchPanel>
          </div>
        </div>
      </span>
    );
  }
}

FamilyMemberUpdate.propTypes = {
  member: PropTypes.shape().isRequired,
  dependents: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  saveFamilyMemberUrl: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
  updateMember: PropTypes.func.isRequired,
  updateFamilyMember: PropTypes.func.isRequired,
  saveNewFamilyMember: PropTypes.func.isRequired,
  saveUpdatedFamilyMember: PropTypes.func.isRequired,
  notEnrolledDependents: PropTypes.func.isRequired,
  fetchDependents: PropTypes.func.isRequired,
  fetchNotEnrolledDependents: PropTypes.func.isRequired,
  fetchMember: PropTypes.func.isRequired,
  addFamilyMember: PropTypes.func.isRequired,
  reEnroll: PropTypes.func.isRequired,
  anyFamilyChanges: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
};

export const mapStateToProps = state => ({
  isLoading: isLoading(state) && isMemberLoading(state),
  hasError: hasError(state),
  member: getMember(state),
  dependents: getDependentsFrom(state),
  anyFamilyChanges: anyFamilyChanges(state),
  saveFamilyMemberUrl: getSaveNewFamilyMemberUrl(state),
  notEnrolledDependents: dependents(state),
});

export const mapDispatchToProps = dispatch => ({
  fetchDependents: () => dispatch(fetchDependents()),
  fetchNotEnrolledDependents: () => dispatch(fetchNotEnrolledDependents()),
  fetchMember: () => dispatch(fetchMemberDetails()),
  reEnroll: familyMember => dispatch(reEnrollFamilyMember(familyMember)),
  addFamilyMember: () => dispatch(addFamilyMember()),
  updateFamilyMember: (property, value, key) =>
    dispatch(updateFamilyMemberProperty(property, value, key)),
  updateMember: (property, value) => dispatch(updateMemberProperty(property, value)),
  saveNewFamilyMember: (url, dependent) => dispatch(saveNewFamilyMember(url, dependent)),
  saveUpdatedFamilyMember: dependent => dispatch(saveUpdatedFamilyMember(dependent)),
  toggleModal: id => dispatch(toggleModal(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FamilyMemberUpdate);
